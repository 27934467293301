import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import useApiRequest from '../../../hooks/api-request';
import { BlockBox, BlockBoxTitle } from '../../common/styles/boxes.styles';
import { Page, PageTitle } from '../../common/styles/page.styles';
import { getProjectPaymentPlan } from '../../../utils/payment-plans';
import { List } from './ShoppingCartPage.styles';
import { getProjectContributorsCount } from '../../../utils/project';
import CartSummaryTable from './CartSummaryTable';
import { routes } from '../../../App';
import { GreenButton } from '../../common/styles/buttons.styles';
import { formatPrice } from '../../../utils/numbers';
/**
 * This is the page where we show to the user how much he will pay for the final video.
 * This page is not intended to be used for creating the order, it is a presentational page.
 */
export default function ShoppingCartPage() {
    const navigate = useNavigate();
    const API = useApiRequest();
    const { id } = useParams();
    const theme = useTheme();
    const [project, setProject] = useState();
    const [contributorsCount, setContributorsCount] = useState(0);
    const [paymentPlan, setPaymentPlan] = useState();
    const [coupon, setCoupon] = useState();
    const [couponSuccess, setCouponSuccess] = useState(false);
    const [couponError, setCouponError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    setLoading(true);
                    const response = await API.getProject(id);
                    setError(false);
                    setProject(response.data.project);
                }
                catch (e) {
                    setError(true);
                }
                finally {
                    setLoading(false);
                }
            }
        })();
    }, [id]);
    // get payment plan for project
    useEffect(() => {
        if (project) {
            const plan = getProjectPaymentPlan(project);
            setPaymentPlan(plan);
            const contributors = getProjectContributorsCount(project);
            setContributorsCount(contributors);
        }
    }, [project]);
    async function validateCart() {
        const qs = coupon ? `?coupon=${coupon.id}` : '';
        navigate(`/${routes.user}/${routes.messages}/${id}/commande${qs}`);
    }
    function deleteCoupon() {
        setCoupon(undefined);
    }
    function closeCouponSuccess() {
        setCouponSuccess(false);
    }
    function closeCouponError() {
        setCouponError(false);
    }
    const hasProjectFiles = !!project?.files?.length;
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: "Ma commande" }), _jsx(Box, { display: "flex", justifyContent: "center", children: _jsxs(Box, { sx: { pt: 2 }, children: [_jsx(Typography, { children: "Le tarif est \u00E9volutif selon le nombre de participant\u00B7es :" }), _jsxs(List, { children: [_jsxs("li", { children: ["Moins de 20 participant\u00B7es = ", formatPrice(14.9)] }), _jsxs("li", { children: ["De 21 \u00E0 60 participant\u00B7es = ", formatPrice(35)] }), _jsxs("li", { children: ["61 participant\u00B7es et plus = ", formatPrice(59)] })] })] }) }), loading && (_jsx(Typography, { sx: { fontStyle: 'italic', color: theme.palette.text.secondary }, children: "Chargement..." })), !loading && error && (_jsx(Typography, { sx: { fontStyle: 'italic', color: theme.palette.error.main }, children: "Une erreur est survenue lors du chargement de votre panier." })), !loading && !error && paymentPlan && (_jsxs(Box, { sx: { mt: 6, textAlign: 'center' }, children: [_jsxs(BlockBox, { children: [_jsx(BlockBoxTitle, { children: "Panier" }), _jsxs(Box, { py: 1, children: [couponSuccess && (_jsx(Alert, { severity: "success", onClose: closeCouponSuccess, children: "Le code promo a \u00E9t\u00E9 appliqu\u00E9." })), couponError && (_jsx(Alert, { severity: "error", onClose: closeCouponError, children: "Le code promo n'est pas valide." }))] }), hasProjectFiles ? (_jsx(CartSummaryTable, { contributorsCount: contributorsCount, price: paymentPlan.price, coupon: coupon, onCouponValidate: setCoupon, onCouponDelete: deleteCoupon, onCouponError: setCouponError })) : (_jsx(Alert, { severity: "warning", children: "Il doit y avoir au moins 1 contenu pour finaliser le projet." }))] }), hasProjectFiles ? (_jsx(GreenButton, { onClick: validateCart, children: "Suivant" })) : (_jsx(GreenButton, { component: Link, to: `/${routes.user}/${routes.messages}/${id}`, children: "Retour" }))] }))] }));
}
