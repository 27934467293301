import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { SwapVert, Edit, Visibility } from '@mui/icons-material';
import MediaThumbnail from './MediaThumbnail';
import { routes } from '../../../../../../App';
import { Link, useParams } from 'react-router-dom';
export function ProjectMediaListItem({ media, index, readonly, onPreview }) {
    const { id } = useParams();
    const theme = useTheme();
    function previewMedia() {
        onPreview(media);
    }
    return (_jsx(Draggable, { draggableId: media.id, index: index, isDragDisabled: readonly, children: (provided, snapshot) => (_jsxs(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", ref: provided.innerRef, ...provided.draggableProps, sx: {
                my: 1,
                p: 1.5,
                borderRadius: "8px",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                background: snapshot.isDragging ? theme.palette.grey.A100 : "white"
            }, children: [_jsxs(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", children: [_jsx(Box, { px: 1, children: _jsx(Typography, { children: index + 1 }) }), _jsx(MediaThumbnail, { type: media.type, url: media.file_path }), _jsx(Box, { flex: 1, px: 1, children: _jsx(Typography, { sx: { wordBreak: "break-word" }, children: media.userName }) })] }), _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", children: [readonly && (_jsx(IconButton, { size: "small", color: "secondary", onClick: previewMedia, children: _jsx(Visibility, {}) })), !readonly && (_jsxs(_Fragment, { children: [_jsx(IconButton, { component: Link, to: `/${routes.user}/${routes.messages}/${id}/medias/${media.id}`, size: "small", color: "secondary", children: _jsx(Edit, {}) }), _jsx(IconButton, { ...provided.dragHandleProps, size: "small", color: "secondary", children: _jsx(SwapVert, {}) })] }))] })] })) }, media.id));
}
