import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { routes } from "../../App";
export default function ContributorsLoginRoutes() {
    const [cookies] = useCookies();
    const { id } = useParams();
    console.log("ContributorsLoginRoutes", id, cookies.hasSeenContributorOnboarding, Boolean(cookies.hasSeenContributorOnboarding));
    return Boolean(cookies.hasSeenContributorOnboarding)
        ? _jsx(Outlet, {})
        : _jsx(Navigate, { to: `/${routes.onboarding}/${id}/0` });
}
