import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, IconButton, MenuItem, Select, Tooltip, ClickAwayListener, useTheme } from '@mui/material';
import { FormatAlignCenter, FormatAlignLeft, FormatAlignRight, Save } from '@mui/icons-material';
import { MediaTextActionsWrapper, MediaTextActionsContainer, ActionButtonContentContainer, ColorBox, SaveButton, } from './MediaTextActions.styles';
import { MEDIA_TEXT_FONT_SIZES, MEDIA_TEXT_FONTS, MEDIA_TEXT_COLORS, } from './MediaEditTextPage.utils';
// @ts-ignore
import editTextBackgroundIcon from "../../../../../public/edit_text_background.svg";
// @ts-ignore
import trashIcon from "../../../../../public/bin_orange.svg";
// @ts-ignore
import editTextColorIcon from "../../../../../public/edit_text_color.svg";
export default function MediaTextActions({ mediaText, onUpdate, onDelete, onSave }) {
    const theme = useTheme();
    const [isColorPickerOpen, setColorPickerOpen] = useState(false);
    const [fontFamily, setFontFamily] = useState(mediaText.fontFamily);
    const [fontSize, setFontSize] = useState(mediaText.fontSize);
    const [textColor, setTextColor] = useState(mediaText.textColor);
    const [textAlign, setTextAlign] = useState(mediaText.textAlign);
    const [textBackgroundColor, setTextBackgroundColor] = useState(mediaText.textBackgroundColor);
    function handleFontFamilyChange(event) {
        setFontFamily(event.target.value);
        onUpdate({
            ...mediaText,
            fontFamily: event.target.value,
        });
    }
    function handleFontSizeChange(event) {
        setFontSize(parseInt(event.target.value, 10));
        onUpdate({
            ...mediaText,
            fontSize: parseInt(event.target.value, 10),
        });
    }
    function handleColorChange(color) {
        setTextColor(color);
        onUpdate({
            ...mediaText,
            textColor: color,
        });
    }
    function toggleColorPicker() {
        setColorPickerOpen((open) => !open);
    }
    function closeColorPicker() {
        setColorPickerOpen(false);
    }
    function handleTextAlignChange() {
        let align;
        switch (mediaText.textAlign) {
            case "left":
                align = "center";
                break;
            case "center":
                align = "right";
                break;
            case "right":
            default:
                align = "left";
                break;
        }
        setTextAlign(align);
        onUpdate({
            ...mediaText,
            textAlign: align,
        });
    }
    if (!mediaText) {
        return null;
    }
    function handleTextBackgroundChange() {
        let background;
        switch (mediaText.textBackgroundColor) {
            case "transparent":
                background = "#fff";
                break;
            case "#fff":
                background = "#000";
                break;
            case "#000":
                background = "#000000b3";
                break;
            case "#000000b3":
            default:
                background = "transparent";
                break;
        }
        setTextBackgroundColor(background);
        onUpdate({
            ...mediaText,
            textBackgroundColor: background,
        });
    }
    return (_jsxs(MediaTextActionsWrapper, { children: [_jsxs(MediaTextActionsContainer, { children: [_jsx(Select, { variant: "standard", size: "small", value: fontFamily, onChange: handleFontFamilyChange, sx: {
                            margin: "0 4px",
                            color: theme.palette.secondary.main,
                            fontSize: "14px",
                            fontWeight: "bold",
                            "&:before, &:hover:before, &:focus:before, &:focus-within:before, &:after": {
                                border: "none !important",
                            },
                            ".MuiSelect-icon": {
                                color: theme.palette.secondary.main,
                            },
                            ".MuiInputBase-input.MuiInput-input": {
                                background: "none",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                            },
                            [`${theme.breakpoints.down('md')}`]: {
                                maxWidth: "70px",
                            }
                        }, children: MEDIA_TEXT_FONTS.map((font) => (_jsx(MenuItem, { value: font, children: font }, font))) }), _jsx(Select, { variant: "standard", size: "small", value: `${fontSize}`, onChange: handleFontSizeChange, sx: {
                            margin: "0 4px",
                            color: theme.palette.secondary.main,
                            fontSize: "14px",
                            fontWeight: "bold",
                            "&:before, &:hover:before, &:focus:before, &:focus-within:before, &:after": {
                                border: "none !important",
                            },
                            ".MuiSelect-icon": {
                                color: theme.palette.secondary.main,
                            },
                            ".MuiInputBase-input.MuiInput-input": {
                                background: "none",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                            }
                        }, children: MEDIA_TEXT_FONT_SIZES.map((size) => (_jsx(MenuItem, { value: size, children: Math.floor(size / 10) }, size))) }), _jsx(ClickAwayListener, { onClickAway: closeColorPicker, children: _jsx("div", { children: _jsx(Tooltip, { open: isColorPickerOpen, onClose: closeColorPicker, PopperProps: {
                                    disablePortal: true,
                                }, arrow: true, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true, placement: "top", title: (_jsx(Box, { display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: 0.5, children: MEDIA_TEXT_COLORS.map((color) => (_jsx(IconButton, { size: "small", onClick: () => handleColorChange(color), children: _jsx(ColorBox, { sx: { background: color } }) }, color))) })), children: _jsx(IconButton, { size: "small", onClick: toggleColorPicker, children: _jsx(ActionButtonContentContainer, { children: _jsx("img", { src: editTextColorIcon, alt: "Couleur du texte", style: { height: "18px" } }) }) }) }) }) }), _jsx(IconButton, { size: "small", onClick: handleTextAlignChange, children: _jsxs(ActionButtonContentContainer, { children: [textAlign === "left" && (_jsx(FormatAlignLeft, { color: "secondary", sx: { fontSize: 18 } })), textAlign === "center" && (_jsx(FormatAlignCenter, { color: "secondary", sx: { fontSize: 18 } })), textAlign === "right" && (_jsx(FormatAlignRight, { color: "secondary", sx: { fontSize: 18 } }))] }) }), _jsx(IconButton, { size: "small", onClick: handleTextBackgroundChange, children: _jsx(ActionButtonContentContainer, { children: _jsx("img", { src: editTextBackgroundIcon, alt: "Couleur de fond du texte", style: {
                                    height: "18px",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    borderColor: mediaText.textBackgroundColor === "transparent" ? theme.palette.secondary.main : "transparent",
                                    background: mediaText.textBackgroundColor === "#000000b3" ? "#c7c1bd" : mediaText.textBackgroundColor,
                                } }) }) }), _jsx(IconButton, { size: "small", onClick: onDelete, children: _jsx(ActionButtonContentContainer, { children: _jsx("img", { src: trashIcon, alt: "supprimer", style: { height: "16px" } }) }) })] }), _jsx(SaveButton, { onClick: onSave, children: _jsx(Save, { sx: { fontSize: 20, color: "white" } }) })] }));
}
