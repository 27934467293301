import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Box, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { Add, Home, Logout } from "@mui/icons-material";
import { routes } from "../../App";
import { getFilter } from "../../utils/theme";
// @ts-ignore
import accountLogo from "../../../public/account_logo.svg";
import useUser from '../../hooks/user';
export { LoggedUser };
function LoggedUser({ color = "inherit" }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const { removeUserSession } = useUser();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        removeUserSession();
        navigate(`/login`);
        console.log('handleLogout');
    };
    if (!cookies.user) {
        return (_jsx(Box, { width: "55px" }));
    }
    return (_jsxs("div", { children: [_jsx(IconButton, { size: "small", "aria-label": "account of current user", "aria-controls": "menu-appbar", "aria-haspopup": "true", onClick: handleMenu, color: color, sx: { transform: "scale(0.75)" }, children: _jsx("img", { src: accountLogo }) }), _jsxs(Menu, { id: "menu-appbar", anchorEl: anchorEl, anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, keepMounted: true, transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, open: Boolean(anchorEl), onClose: handleClose, sx: { mt: 4 }, children: [_jsxs(MenuItem, { sx: { cursor: "pointer" }, onClick: () => {
                            handleClose();
                            navigate(`/${routes.user}/${routes.messages}`);
                        }, children: [_jsx(Home, { sx: { width: "20px", color: theme.palette.primary.main } }), _jsx(Typography, { sx: { ml: 2 }, children: "Mes projets" })] }), _jsxs(MenuItem, { sx: { cursor: "pointer" }, onClick: () => {
                            handleClose();
                            navigate(`/${routes.user}/${routes.messages}/nouveau`);
                        }, children: [_jsx(Add, { sx: { width: "20px", color: theme.palette.primary.main } }), _jsx(Typography, { sx: { ml: 2 }, children: "Nouvelle vid\u00E9o " })] }), _jsxs(MenuItem, { sx: { cursor: "pointer" }, onClick: () => {
                            handleClose();
                            navigate(`/${routes.user}/infos`);
                        }, children: [_jsx("img", { src: accountLogo, style: { width: "20px", filter: getFilter("green") } }), _jsx(Typography, { sx: { ml: 2 }, children: "Mes informations" })] }), _jsxs(MenuItem, { sx: { cursor: "pointer" }, onClick: () => handleLogout(), children: [_jsx(Logout, { sx: { width: "20px", color: theme.palette.primary.main } }), _jsx(Typography, { sx: { ml: 2 }, children: "D\u00E9connexion" })] })] })] }));
}
