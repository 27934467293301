import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { TabSectionTitle } from '../ProjectMusicTab.styles';
import { Box, Typography, useTheme } from '@mui/material';
import InfoTooltip from '../../../../../common/tooltips/InfoTooltip';
import ProjectMusicFilesList from './ProjectMusicFilesList';
import { formatAudioDuration, PROJECT_MUSIC_SELECTION_ID } from '../ProjectMusicTab.utils';
import ProjectMusicLoadingFiles from './ProjectMusicLoadingFiles';
export default function ProjectMusicSelection({ loadingFiles, projectMusicFiles, playingMusicFileId, finalVideoDuration, isPlayingMusic, readonly, onMusicPlay, onMusicPause, onUpdateFiles, onDeleteFile }) {
    const theme = useTheme();
    const [musicDuration, setMusicDuration] = useState(0);
    useEffect(() => {
        if (projectMusicFiles) {
            const duration = projectMusicFiles.reduce((total, file) => total + file.duration, 0);
            setMusicDuration(duration);
        }
    }, [projectMusicFiles]);
    return (_jsxs(Box, { id: PROJECT_MUSIC_SELECTION_ID, px: 1, py: 2, children: [_jsx(TabSectionTitle, { children: "Votre s\u00E9lection" }), _jsx(ProjectMusicFilesList, { files: projectMusicFiles, playingFileId: playingMusicFileId, readonly: readonly, isPlayingMusic: isPlayingMusic, onMusicPlay: onMusicPlay, onMusicPause: onMusicPause, onUpdateFiles: onUpdateFiles, onDeleteFile: onDeleteFile }), _jsx(ProjectMusicLoadingFiles, { files: loadingFiles }), _jsxs(Box, { pt: 2, children: [_jsxs(Typography, { variant: "body2", sx: { color: finalVideoDuration > musicDuration ? theme.palette.secondary.main : theme.palette.common.black }, children: ["Dur\u00E9e totale de la musique: ", formatAudioDuration(musicDuration), ".", _jsx(InfoTooltip, { children: _jsx("span", { children: "Si la dur\u00E9e totale de la musique est inf\u00E9rieure \u00E0 la celle de la vid\u00E9o, n'h\u00E9sitez pas \u00E0 ajouter d'autres morceaux. Sinon la musique sera jou\u00E9e en boucle par d\u00E9faut." }) })] }), _jsxs(Typography, { variant: "body2", sx: { fontStyle: "italic" }, children: ["Dur\u00E9e actuelle de la vid\u00E9o: ", formatAudioDuration(finalVideoDuration), "."] })] })] }));
}
