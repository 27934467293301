import { styled } from "@mui/material/styles";
import { Container, TextField, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// @ts-ignore
import backgroundUrl from "../../../../public/memorable_m_white.svg";
export const LoginPageWrapper = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#F9F1EC",
    backgroundImage: `url(${backgroundUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
    backgroundSize: "70%",
    marginTop: 0,
    minHeight: "100vh",
    width: "100vw",
    padding: theme.spacing(0, 2),
}));
export const LoginTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "PT Serif",
    fontStyle: "italic",
    fontSize: theme.spacing(2.5),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
}));
export const LoginTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    margin: "auto",
    color: "black",
}));
export const LoginTypo = styled(Typography)(({ theme }) => ({
    width: "100%",
    margin: "auto",
    color: "black",
}));
export const LoginLogo = styled("img")(({ theme }) => ({
    fontFamily: "PT Serif",
    fontStyle: "italic",
    lineHeight: "29px",
    margin: "0 auto",
    ["@media (max-width:960px)"]: {
        width: "60%",
    },
    ["@media (min-width:960px)"]: {
        width: "40%",
    },
}));
export const LoginLoadingButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    fontFamily: "var(--main_font_family_primary)",
    maxWidth: "fit-content",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));
export const WhiteTextField = styled(TextField)(({ theme }) => ({
    "&.MuiTextField-root": {
        width: "100%",
        ".MuiFormLabel-root": {
            color: theme.palette.common.white,
        },
        ".MuiInputBase-root": {
            color: theme.palette.common.white,
        },
        ".MuiInputBase-root.MuiInput-underline::before": {
            borderColor: theme.palette.common.white,
        },
        ".MuiInputBase-root.MuiInput-underline::after": {
            borderColor: theme.palette.common.white,
        }
    },
}));
