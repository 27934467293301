import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import qrcode from 'qrcode';
import { Container } from "@mui/material";
import QRCodeDownload from './QRCodeDownload';
import QRCodePrint from './QRCodePrint';
import { routes } from '../../../../App';
export default function InviteQRCodePage({ isShare = false }) {
    const { id } = useParams();
    const [qrCode, setQrCode] = useState("");
    useEffect(() => {
        (async () => {
            if (id) {
                const url = isShare
                    ? `${process.env.REACT_APP_BASE_URL}/${routes.messages}/${id}/video-finale`
                    : `${process.env.REACT_APP_BASE_URL}/${routes.contributor}/${routes.messages}/${id}/login`;
                const dataUrl = await qrcode.toDataURL(url, { width: 200, margin: 2 });
                setQrCode(dataUrl);
            }
        })();
    }, [id]);
    return (_jsxs(Container, { sx: { pt: 0.25 }, children: [_jsx(QRCodeDownload, { qrCode: qrCode }), _jsx(QRCodePrint, { qrCode: qrCode, isShare: isShare })] }));
}
