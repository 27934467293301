import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { Alert, Box, CircularProgress, Modal, Stack } from '@mui/material';
import { useCookies } from "react-cookie";
import { Link, useParams, Navigate } from 'react-router-dom';
import { ModalWrapper, ModalContent, ModalTitle } from "../../common/styles/modals.styles";
import useApiRequest from '../../../hooks/api-request';
import { theme } from '../../../utils/theme';
import { SecondaryWhiteButton } from '../../common/styles/buttons.styles';
import { routes } from '../../../App';
import { pluralize } from '../../../utils/strings';
import { getRequestErrorMessage } from '../../../utils/errors';
// @ts-ignore
import checkWhiteSrc from "../../../../public/check_white.svg";
export default function UploadModal({ open, onClose, medias, updateMedia, hasInvalidFiles }) {
    const { id } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies();
    const role = cookies.role;
    const user = cookies.user;
    const API = useApiRequest();
    const [uploading, setUploading] = useState(medias.some((media) => media.status !== "uploaded"));
    const [uploadErrors, setUploadErrors] = useState([]);
    useEffect(() => {
        (async () => {
            // wait for previous media to be uploaded
            // NOTE: this is crucial to prevent concurrent project medias updates which can cause lost data
            if (!medias.some((media) => media.status === "waiting")) {
                // find a media to upload
                const media = medias.find((media) => media.status === "toValidate");
                if (media) {
                    await uploadAndSaveMedia(media);
                }
                else {
                    // determine if all media are uploaded
                    if (medias.every((media) => media.status === "uploaded" || media.status === "error")) {
                        setUploading(false);
                    }
                }
            }
        })();
    }, [medias]);
    useEffect(() => {
        if (!open && uploadErrors.length > 0) {
            // reset errors on close
            setUploadErrors([]);
        }
    }, [open]);
    async function uploadAndSaveMedia(media) {
        try {
            updateMedia(media.id, { status: "waiting" });
            if (id) {
                const formData = new FormData();
                formData.append("file", media.file);
                formData.append("userId", `${user.id}`);
                formData.append("role", role);
                await API.uploadProjectFile(id, formData);
                updateMedia(media.id, { status: "uploaded" });
            }
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setUploadErrors(() => [...uploadErrors, message]);
            updateMedia(media.id, { status: "error" });
        }
    }
    return (_jsx(Modal, { open: open, onClose: onClose, children: _jsx(ModalWrapper, { children: _jsx(ModalContent, { display: "flex", flexDirection: "column", justifyContent: "space-between", children: uploading ? (_jsxs(_Fragment, { children: [_jsx(ModalTitle, { children: "Vos contenus sont en train d\u2019\u00EAtre ajout\u00E9s..." }), _jsx(Box, { sx: { pb: 2, flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) })] })) : role === "organizer" ? (_jsxs(_Fragment, { children: [_jsx(ModalTitle, { children: "Vos contenus ont bien \u00E9t\u00E9 ajout\u00E9s !" }), _jsxs(Box, { flex: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", children: [_jsx(Box, { flex: 1, display: "flex", alignItems: "center", justifyContent: "center", children: _jsx("img", { alt: "check", src: checkWhiteSrc }) }), hasInvalidFiles && (_jsx(Box, { pt: 2, children: _jsx(Alert, { severity: "warning", children: "Certains fichiers ne sont pas valides et n'ont pas pu \u00EAtre ajout\u00E9s." }) })), uploadErrors.length > 0 && (_jsx(Box, { pt: 2, children: _jsxs(Alert, { severity: "error", children: ["Le t\u00E9l\u00E9chargement ", pluralize(uploadErrors.length, "du fichier", "des fichiers"), " ", medias.filter((m) => m.status === "error").map((m) => m.file.name).join(', '), " a \u00E9chou\u00E9."] }) })), _jsxs(Stack, { mt: 4, direction: "column", spacing: 2, alignItems: "stretch", justifyContent: "center", width: "100%", children: [_jsx(SecondaryWhiteButton, { onClick: onClose, sx: { maxWidth: "none" }, children: "Ajouter un nouveau contenu" }), _jsx(SecondaryWhiteButton, { component: Link, to: `/${routes.user}/${routes.messages}/${id}`, sx: {
                                                maxWidth: "none",
                                                background: theme.palette.common.white,
                                                color: theme.palette.secondary.main
                                            }, children: "Retour \u00E0 la liste des contenus" })] })] })] })) : (_jsx(Navigate, { to: `/${routes.messages}/${id}/merci${hasInvalidFiles ? '?has_invalid_files=1' : ''}` })) }) }) }));
}
