import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
export const PrimaryButton = styled(Button)(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    fontFamily: "var(--main_font_family_primary)",
    maxWidth: "fit-content",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    "&.Mui-disabled": {
        background: theme.palette.grey[300],
        boxShadow: "none",
        pointerEvents: "none",
        cursor: "default",
    }
}));
export const PrimaryInvertedButton = styled(PrimaryButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
    },
}));
export const PrimaryWhiteButton = styled(PrimaryButton)(({ theme }) => ({
    "&:hover": {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
}));
export const SecondaryButton = styled(Button)(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    fontFamily: "var(--main_font_family_primary)",
    maxWidth: "fit-content",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    "&.Mui-disabled": {
        background: theme.palette.grey[300],
        boxShadow: "none",
        pointerEvents: "none",
        cursor: "default",
    }
}));
export const SecondaryInvertedButton = styled(SecondaryButton)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.main,
    },
}));
export const SecondaryWhiteButton = styled(SecondaryButton)(({ theme }) => ({
    "&:hover": {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
}));
export const OrangeButton = styled(Button)(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    fontFamily: "var(--main_font_family_primary)",
    maxWidth: "fit-content",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));
export const WhiteButton = styled(Button)(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "white",
    color: theme.palette.secondary.main,
    textTransform: "none",
    fontFamily: "var(--main_font_family_primary)",
    maxWidth: "fit-content",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));
export const WhiteSecondaryButton = styled(WhiteButton)(({ theme }) => ({
    "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));
export const GreenButton = styled(Button)(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    fontFamily: "var(--main_font_family_primary)",
    maxWidth: "fit-content",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));
