import { useCookies } from 'react-cookie';
import axios from 'axios';
export default function useApiRequest() {
    const [cookies] = useCookies();
    const request = axios.create({});
    const authRequest = axios.create({
        headers: {
            Authorization: `Bearer ${cookies.token}`,
        },
    });
    // ORGANIZERS
    async function organizerLogin(email, password) {
        return request.post(`${process.env.REACT_APP_API_URL}/organizer/login`, { email, password });
    }
    async function organizerSignup(username, email, password) {
        return request.post(`${process.env.REACT_APP_API_URL}/organizer/signup`, { username, email, password });
    }
    async function organizerPasswordResetRequest(email) {
        return request.post(`${process.env.REACT_APP_API_URL}/organizer/password-reset-request`, { email });
    }
    async function organizerPasswordResetVerify(token) {
        return request.post(`${process.env.REACT_APP_API_URL}/organizer/password-reset-verify`, { token });
    }
    async function organizerPasswordReset(token, password) {
        return request.post(`${process.env.REACT_APP_API_URL}/organizer/password-reset`, { token, password });
    }
    // CONTRIBUTORS
    async function contributorLogin(projectId, email, username) {
        return request.post(`${process.env.REACT_APP_API_URL}/project/${projectId}/contributors`, { email, username });
    }
    // PROJECTS
    async function createProject(project) {
        return authRequest.post(`${process.env.REACT_APP_API_URL}/projects`, project);
    }
    async function getUserProjects(userId) {
        return authRequest.get(`${process.env.REACT_APP_API_URL}/project/get/user/${userId}`);
    }
    async function getProject(projectId) {
        return authRequest.get(`${process.env.REACT_APP_API_URL}/project/${projectId}`);
    }
    async function deleteProject(projectId) {
        return authRequest.delete(`${process.env.REACT_APP_API_URL}/project/${projectId}`);
    }
    async function getProjectFiles(projectId, contributorId) {
        if (contributorId) {
            return request.get(`${process.env.REACT_APP_API_URL}/projects/${projectId}/files/user/${contributorId}`);
        }
        return authRequest.get(`${process.env.REACT_APP_API_URL}/projects/${projectId}/files`);
    }
    async function uploadProjectFile(projectId, data) {
        return request.post(`${process.env.REACT_APP_API_URL}/projects/${projectId}/files/upload`, data);
    }
    async function updateProjectFile(projectId, mediaId, media) {
        return authRequest.put(`${process.env.REACT_APP_API_URL}/projects/${projectId}/files/${mediaId}`, { file: media });
    }
    async function updateProjectFilesOrder(projectId, medias, contributorId) {
        if (contributorId) {
            return request.post(`${process.env.REACT_APP_API_URL}/projects/${projectId}/files/user/${contributorId}`, { files: medias });
        }
        return authRequest.post(`${process.env.REACT_APP_API_URL}/projects/${projectId}/files`, { files: medias });
    }
    async function deleteProjectFile(projectId, fileId, contributorId) {
        if (contributorId) {
            return request.delete(`${process.env.REACT_APP_API_URL}/projects/${projectId}/files/${fileId}/users/${contributorId}`);
        }
        return authRequest.delete(`${process.env.REACT_APP_API_URL}/projects/${projectId}/files/${fileId}`);
    }
    async function uploadProjectMusicFile(projectId, file) {
        return authRequest.post(`${process.env.REACT_APP_API_URL}/projects/${projectId}/music/files/upload`, file);
    }
    async function updateProjectMusicFiles(projectId, files) {
        return authRequest.put(`${process.env.REACT_APP_API_URL}/projects/${projectId}/music/files`, { files });
    }
    async function deleteProjectMusicFile(projectId, fileId) {
        return authRequest.delete(`${process.env.REACT_APP_API_URL}/projects/${projectId}/music/files/${fileId}`);
    }
    async function updateProject(projectId, updates) {
        return authRequest.put(`${process.env.REACT_APP_API_URL}/projects/${projectId}`, updates);
    }
    async function updateProjectMusic(projectId, updates) {
        return authRequest.put(`${process.env.REACT_APP_API_URL}/projects/${projectId}/music`, updates);
    }
    async function getProjectsContributors(projectId) {
        return authRequest.get(`${process.env.REACT_APP_API_URL}/project/${projectId}/contributors`);
    }
    async function updateProjectStatus(projectId, status) {
        return authRequest.put(`${process.env.REACT_APP_API_URL}/project/${projectId}`, { status });
    }
    async function createProjectPaymentIntent(projectId, couponId) {
        return authRequest.post(`${process.env.REACT_APP_API_URL}/projects/${projectId}/payment-intent`, { couponId });
    }
    async function finalizeProject(projectId) {
        return authRequest.get(`${process.env.REACT_APP_API_URL}/projects/${projectId}/finalize`);
    }
    // ORDERS
    async function createProjectOrder(projectId, order) {
        return authRequest.post(`${process.env.REACT_APP_API_URL}/projects/${projectId}/orders`, order);
    }
    async function getProjectOrders(projectId) {
        return authRequest.get(`${process.env.REACT_APP_API_URL}/projects/${projectId}/orders`);
    }
    async function getProjectOrder(projectId, orderId) {
        return authRequest.get(`${process.env.REACT_APP_API_URL}/projects/${projectId}/orders/${orderId}`);
    }
    async function getProjectOrderPaymentIntent(projectId, orderId) {
        return authRequest.get(`${process.env.REACT_APP_API_URL}/projects/${projectId}/orders/${orderId}/payment-intent`);
    }
    async function updateProjectOrderStatus(projectId, orderId, updates) {
        return authRequest.put(`${process.env.REACT_APP_API_URL}/projects/${projectId}/orders/${orderId}/status`, updates);
    }
    async function verifyStripeCoupon(code) {
        return authRequest.get(`${process.env.REACT_APP_API_URL}/stripe/coupons/${code}`);
    }
    return {
        organizerLogin,
        organizerSignup,
        organizerPasswordResetRequest,
        organizerPasswordResetVerify,
        organizerPasswordReset,
        contributorLogin,
        createProject,
        getUserProjects,
        getProject,
        deleteProject,
        getProjectFiles,
        uploadProjectFile,
        updateProjectFile,
        updateProjectFilesOrder,
        deleteProjectFile,
        uploadProjectMusicFile,
        updateProjectMusicFiles,
        deleteProjectMusicFile,
        updateProject,
        updateProjectMusic,
        getProjectsContributors,
        updateProjectStatus,
        finalizeProject,
        getProjectOrders,
        getProjectOrder,
        createProjectOrder,
        createProjectPaymentIntent,
        getProjectOrderPaymentIntent,
        updateProjectOrderStatus,
        verifyStripeCoupon,
    };
}
