import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { IconImage, MenuContainer, MenuItemContainer, MenuItemDivider, MenuItemIconButton, MenuItemText } from '../styles/bottom-navigation-menu.styles';
import { routes } from '../../../App';
import useApiRequest from '../../../hooks/api-request';
import ProjectMediaDeleteModal from '../../Project/Dashboard/ProjectContent/ProjectMediaTab/ProjectMediaList/ProjectMediaDeleteModal';
// @ts-ignore
import text from '../../../../public/text_green.svg';
// @ts-ignore
import speaker from '../../../../public/speaker_green.svg';
// @ts-ignore
import scissors from '../../../../public/scissors_green.svg';
// @ts-ignore
import trash from '../../../../public/bin_green.svg';
export function MediaEditNavigation() {
    const navigate = useNavigate();
    const location = useLocation();
    const { id, mediaId } = useParams();
    const [cookies] = useCookies();
    const API = useApiRequest();
    const role = cookies.role;
    const user = cookies.user;
    const [media, setMedia] = useState();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    useEffect(() => {
        (async () => {
            if (id && mediaId) {
                try {
                    const response = await API.getProjectFiles(id);
                    if (response.data.files) {
                        const media = response.data.files.find((media) => media.id === mediaId);
                        setMedia(media);
                    }
                }
                catch {
                    console.error("Error while fetching media");
                }
            }
        })();
    }, [id, mediaId]);
    async function deleteMedia() {
        if (id && mediaId) {
            try {
                await API.deleteProjectFile(id, mediaId, role === "contributor" ? user.id : null);
                navigate(`/${routes.user}/${routes.messages}/${id}`);
            }
            catch {
                console.error("Error while deleting media");
            }
        }
    }
    function openDeleteModal() {
        setDeleteModalOpen(true);
    }
    function closeDeleteModal() {
        setDeleteModalOpen(false);
    }
    if (!media) {
        return null;
    }
    return (_jsxs(MenuContainer, { children: [_jsxs(MenuItemContainer, { component: Link, to: `/${routes.user}/${routes.messages}/${id}/medias/${mediaId}/texte`, className: location.pathname.endsWith(`/${mediaId}/texte`) ? "active" : "", children: [_jsx(MenuItemIconButton, { children: _jsx(IconImage, { src: text, alt: "editer le projet" }) }), _jsx(MenuItemText, { children: "Texte" })] }), media.type === "video" && (_jsxs(_Fragment, { children: [_jsx(MenuItemDivider, {}), _jsxs(MenuItemContainer, { component: Link, to: `/${routes.user}/${routes.messages}/${id}/medias/${mediaId}/volume`, className: location.pathname.endsWith(`/${mediaId}/volume`) ? "active" : "", children: [_jsx(MenuItemIconButton, { children: _jsx(IconImage, { src: speaker, alt: "inviter des participants" }) }), _jsx(MenuItemText, { children: "Volume" })] })] })), media.type === "video" && (_jsxs(_Fragment, { children: [_jsx(MenuItemDivider, {}), _jsxs(MenuItemContainer, { component: Link, to: `/${routes.user}/${routes.messages}/${id}/medias/${mediaId}/raccourcir`, className: location.pathname.endsWith(`/${mediaId}/raccourcir`) ? "active" : "", children: [_jsx(MenuItemIconButton, { children: _jsx(IconImage, { src: scissors, alt: "raccourcir" }) }), _jsx(MenuItemText, { children: "Raccourcir" })] })] })), _jsx(MenuItemDivider, {}), _jsxs(MenuItemContainer, { children: [_jsx(MenuItemIconButton, { onClick: openDeleteModal, children: _jsx(IconImage, { alt: "commande", src: trash }) }), _jsx(MenuItemText, { children: "Supprimer" })] }), media && deleteModalOpen && (_jsx(ProjectMediaDeleteModal, { media: media, onClose: closeDeleteModal, onDelete: deleteMedia }))] }));
}
