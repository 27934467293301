import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';
import useApiRequest from '../../../hooks/api-request';
import useUrlSearchParams from '../../../hooks/url-search-params';
import { Page, PageTitle } from '../../common/styles/page.styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import OrderForm from './OrderForm';
import FreeOrderForm from './FreeOrderForm';
import { OrderStatus } from '../../../models/order';
import { routes } from '../../../App';
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY || '');
/**
 * This is the page where we the order is created and completed after the payment.
 */
export default function OrderPage() {
    const API = useApiRequest();
    const navigate = useNavigate();
    const { id, orderId } = useParams();
    const params = useUrlSearchParams();
    const theme = useTheme();
    const [order, setOrder] = useState();
    const [stripeClientSecret, setStripeClientSecret] = useState();
    const [isFreeProject, setIsFreeProject] = useState(false);
    const [error, setError] = useState(false);
    // get project payment intent
    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    // Verify that no order already exists
                    const { data: { orders } } = await API.getProjectOrders(id);
                    if (orders.length > 0) {
                        const order = orderId ? orders.find((o) => o.id === orderId) : orders[0];
                        if (order.status === OrderStatus.Paid) {
                            navigate(`/${routes.user}/${routes.messages}/${id}/merci`);
                            return;
                        }
                        setOrder(order);
                    }
                    const response = await API.createProjectPaymentIntent(id, params.get('coupon'));
                    setStripeClientSecret(response.data.paymentIntentClientSecret);
                }
                catch (error) {
                    console.error('Existing order verification or payment intent creation failed', error);
                    if (error.response?.data?.amount === 0) {
                        // Show free order when order amount is zero to avoid stripe payment
                        setIsFreeProject(true);
                    }
                    else {
                        setError(true);
                    }
                }
            }
        })();
    }, [id, orderId]);
    async function handlePaymentSuccess() {
        if (id && orderId) {
            try {
                await API.updateProjectOrderStatus(id, orderId, { status: OrderStatus.Paid });
                navigate(`/${routes.user}/${routes.messages}/${id}/merci`);
            }
            catch (e) {
                console.log('Cannot update order status', e);
            }
        }
    }
    async function handleFreeOrderSuccess() {
        if (id) {
            navigate(`/${routes.user}/${routes.messages}/${id}/merci`);
        }
    }
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: "Votre commande" }), stripeClientSecret && (_jsx(Elements, { options: {
                    clientSecret: stripeClientSecret,
                    appearance: {
                        theme: 'flat',
                        variables: {
                            colorPrimary: theme.palette.primary.main,
                        },
                    },
                }, stripe: stripePromise, children: _jsx(OrderForm, { order: order, couponId: params.get('coupon'), handlePaymentSuccess: handlePaymentSuccess }) })), !stripeClientSecret && isFreeProject && (_jsx(FreeOrderForm, { order: order, couponId: params.get('coupon'), handleSuccess: handleFreeOrderSuccess })), error && (_jsx(Typography, { children: "Une erreur est survenue lors du chargement du formulaire, veuillez contacter le support." }))] }));
}
