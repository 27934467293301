import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import axios from "axios";
import { Alert } from '@mui/material';
import { Stack } from "@mui/system";
import { Page, PageTitle } from "../../common/styles/page.styles";
import InviteEmailForm from './InviteEmailForm';
import ContributorsEmailsList from './ContributorsEmailsList';
import InviteLinks from './InviteLinks';
import { Divider } from './InviteContributorsPage.styles';
import { getRequestErrorMessage } from '../../../utils/errors';
function InviteContributorsPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [project, setProject] = useState();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [linkCopied, setLinkCopied] = useState(false);
    const [emails, setEmails] = useState([]);
    const [updated, setUpdated] = useState(true);
    const [error, setError] = useState(null);
    const token = cookies.token;
    useEffect(() => {
        (async () => {
            await getProject();
        })();
    }, [id]);
    useEffect(() => {
        (async () => {
            if (updated) {
                await getInvites();
                await getProject();
                setUpdated(false);
            }
        })();
    }, [updated]);
    async function getProject() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/project/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setProject(response.data.project);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    async function getInvites() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/project/${id}/sharing`);
            setEmails(response.data.emails);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    async function handleEmailsAdd(addedEmails) {
        setEmails([...new Set([...emails, ...addedEmails])]);
        await sendInvites();
    }
    async function deleteInvite(email) {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/project/${id}/sharing`, {
                data: { email },
            });
            setUpdated(true);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    async function sendInvites() {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/project/${id}/sharing/send`);
            setUpdated(true);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: "Inviter les participant\u00B7es" }), linkCopied && _jsx(Alert, { severity: "success", children: "Lien copi\u00E9." }), _jsx(InviteLinks, { project: project, onLinkCopied: setLinkCopied }), _jsx(Divider, { children: "ou" }), _jsx(InviteEmailForm, { onEmailsAdd: handleEmailsAdd, onEmailsError: setError }), _jsx(ContributorsEmailsList, { emails: emails, onDeleteInvite: deleteInvite }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) }))] }));
}
export default InviteContributorsPage;
