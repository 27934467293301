import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import { PlayCircleOutlined, PauseCircleOutlined, AddCircleOutlineOutlined } from '@mui/icons-material';
import { formatAudioDuration } from '../ProjectMusicTab.utils';
export default function ProjectMusicLibraryListItem({ item, isPlaying, onPlay, onPause, onAdd }) {
    function handlePlay() {
        onPlay(item, true);
    }
    function handleAdd() {
        onAdd(item);
    }
    return (_jsxs(Grid, { item: true, xs: 6, sm: 3, md: 3, lg: 2, display: "flex", flexDirection: "column", children: [_jsxs(Typography, { variant: "body2", children: [item.file_name, " - ", formatAudioDuration(item.duration)] }), _jsx(Box, { pt: 1, children: _jsx("img", { alt: `${item.file_name} cover`, src: item.cover, style: { maxWidth: "100%" } }) }), _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", children: [_jsx(IconButton, { size: "small", color: "secondary", onClick: isPlaying ? onPause : handlePlay, children: isPlaying ? _jsx(PauseCircleOutlined, {}) : _jsx(PlayCircleOutlined, {}) }), _jsx(IconButton, { size: "small", color: "secondary", onClick: handleAdd, children: _jsx(AddCircleOutlineOutlined, {}) })] })] }));
}
