import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
// @ts-ignore
import backgroundImage from "../../../public/background_header.png";
// @ts-ignore
import webIcon from "../../../public/web_icon.svg";
// @ts-ignore
import mailIcon from "../../../public/mail_icon.svg";
// @ts-ignore
import facebookIcon from "../../../public/facebook_icon.svg";
// @ts-ignore
import instagramIcon from "../../../public/instagram_icon.svg";
// @ts-ignore
import tiktokIcon from "../../../public/tiktok_icon.svg";
export default function SocialLinksFooter() {
    const theme = useTheme();
    const socialItems = [
        { link: "https://www.memorable.love", name: "web", icon: webIcon },
        { link: "mailto:hello@memorable.love", name: "mail", icon: mailIcon },
        { link: "https://www.instagram.com/memorable.app.love", name: "instagram", icon: instagramIcon },
        { link: "https://www.tiktok.com/@memorable.app.love", name: "tiktok", icon: tiktokIcon },
        { link: "https://www.facebook.com/memorable.app.love", name: "facebook", icon: facebookIcon },
    ];
    return (_jsxs(Box, { sx: {
            backgroundImage: `url(${backgroundImage})`,
            p: 1,
            color: theme.palette.common.white,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        }, children: [_jsx(Typography, { children: "Retrouvez M\u00E9morable" }), _jsx(Box, { sx: { ml: 1, display: 'flex', alignItems: 'center' }, children: socialItems.map((item, index) => (_jsx(Link, { to: item.link, target: "_blank", rel: "noopener noreferrer", style: { lineHeight: 0 }, children: _jsx("img", { style: { height: "15px", margin: "4px" }, src: item.icon, alt: `${item.name} icon` }) }, item.name))) })] }));
}
