import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import { Box, Divider, IconButton, Typography } from '@mui/material';
export const MenuContainer = styled(Box)(({ theme }) => ({
    background: "white",
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1.5, 0),
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1, 0),
    }
}));
export const MenuItemText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
}));
export const MenuItemContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    textAlign: "center",
    transition: "opacity 200ms ease",
    ['&:not(.active)']: {
        opacity: 0.6,
    },
    ['&:not(.active):hover']: {
        opacity: 1,
        // background: theme.palette.common.white,
    },
}));
export const MenuItemDivider = styled((props) => (_jsx(Divider, { orientation: "vertical", flexItem: true, ...props })))(({ theme }) => ({
    alignSelf: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
}));
export const MenuItemIconButton = styled(IconButton)(({ theme }) => ({
    height: "fit-content",
    border: `2px solid ${theme.palette.primary.main}`,
    mb: 0.5,
    transition: "opacity 200ms ease",
    // ['&:not(.active)']: {
    //   opacity: 0.6,
    // },
    ['&:not(.active):hover']: {
        // opacity: 1,
        background: theme.palette.common.white,
    },
}));
export const IconImage = styled('img')(({ theme }) => ({
    width: "25px",
    height: "25px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
        width: "20px",
        height: "20px",
    }
}));
