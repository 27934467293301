import { OrderStatus } from '../models/order';
/**
 * Get last order from orders array
 * @param orders
 */
export function getLastOrder(orders) {
    return !!orders.length ? orders[orders.length - 1] : null;
}
/**
 * Verify if order status is Paid
 * @param order
 */
export function isOrderPaid(order) {
    return order.status === OrderStatus.Paid;
}
