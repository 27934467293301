import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { AppBar, Box, Grid, Toolbar } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { LoggedUser } from "./LoggedUser";
import { routes } from "../../App";
// @ts-ignore
import backgroundImage from "../../../public/background_header.png";
// @ts-ignore
import logo_white from "../../../public/logo_white.svg";
export const LogoHeader = styled("img")(({ theme }) => ({
    cursor: "pointer",
    maxWidth: "200px",
    ["@media (max-width:960px)"]: {
        maxWidth: "50%",
    },
}));
export default function LayoutHeader() {
    const navigate = useNavigate();
    const { id } = useParams();
    const path = useLocation();
    const [cookies] = useCookies();
    const role = cookies.role;
    const withBackArrow = path.pathname !== `/${routes.user}/${routes.messages}` && !(id && path.pathname === `/${routes.contributor}/${routes.messages}/${id}`);
    function handleBackNavigation() {
        let nextPath = -1;
        switch (role) {
            case "organizer": {
                // Go back to projects list when we are on project overview page
                if (path.pathname.endsWith(`/${routes.messages}/${id}`)) {
                    nextPath = `/${routes.user}/${routes.messages}`;
                }
                // When editing project media, go back to project dashboard
                if (path.pathname.endsWith('texte') || path.pathname.endsWith('volume') || path.pathname.endsWith('raccourcir')) {
                    nextPath = `/${routes.user}/${routes.messages}/${id}`;
                }
                // When just created we don't want to go back to creation form
                if (path.pathname.endsWith('invite') || path.pathname.endsWith('merci') || path.pathname.endsWith('video-finale')) {
                    nextPath = `/${routes.user}/${routes.messages}/${id}`;
                }
                break;
            }
            case "contributor": {
                if (id) {
                    nextPath = `/${routes.contributor}/${routes.messages}/${id}`;
                }
                break;
            }
            default:
                break;
        }
        navigate(nextPath);
    }
    return (_jsx(AppBar, { color: "primary", position: "static", sx: { backgroundImage: `url(${backgroundImage})` }, children: _jsxs(Toolbar, { className: "flex justify-between", sx: {
                justifyContent: "space-between",
                width: "80%",
                margin: "auto",
                p: 1,
            }, children: [_jsx(Grid, { sx: { width: "34px" }, children: withBackArrow && (_jsx(ArrowBackIos, { className: "cursor-pointer", style: { height: 32, padding: 1, cursor: "pointer" }, onClick: handleBackNavigation })) }), _jsx(LogoHeader, { src: logo_white, onClick: () => {
                        if (role === "organizer") {
                            navigate(`${routes.messages}`);
                        }
                    } }), _jsxs(Box, { className: "flex items-center", children: [role !== "contributor" && _jsx(LoggedUser, {}), role === "contributor" && _jsx(Grid, { sx: { width: "34px" } })] })] }) }));
}
