import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Alert, Box, Chip, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Add } from '@mui/icons-material';
import { routes } from '../../../App';
import { GreenButton, OrangeButton } from '../../common/styles/buttons.styles';
import DeleteProjectModal from './DeleteProjectModal';
import { Page, ProjectListPageTitle } from '../../common/styles/page.styles';
import { ProjectStatus } from '../../../models/project';
import { getRequestErrorMessage } from '../../../utils/errors';
// @ts-ignore
import trash from '../../../../public/bin_orange.svg';
import useApiRequest from '../../../hooks/api-request';
export default function ProjectListPage() {
    const API = useApiRequest();
    const [error, setError] = useState(null);
    const [projects, setProjects] = useState([]);
    const [deleteProjectId, setDeleteProjectId] = useState();
    const [cookies, setCookie, removeCookie] = useCookies();
    const user = cookies.user;
    useEffect(() => {
        (async () => {
            try {
                const response = await API.getUserProjects(user.id);
                setProjects(response.data.projects);
            }
            catch (error) {
                const message = getRequestErrorMessage(error);
                setError(message);
            }
        })();
    }, []);
    async function fetchProjects() {
        try {
            const response = await API.getUserProjects(user.id);
            setProjects(response.data.projects);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    async function deleteProject() {
        if (deleteProjectId) {
            try {
                await API.deleteProject(deleteProjectId);
                await fetchProjects();
            }
            catch (error) {
                const message = getRequestErrorMessage(error);
                setError(message);
            }
            finally {
                setDeleteProjectId(undefined);
            }
        }
    }
    const handleClose = () => {
        setDeleteProjectId(undefined);
    };
    return (_jsxs(Page, { children: [_jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsxs(GreenButton, { component: Link, color: "primary", variant: "contained", to: `/${routes.user}/${routes.messages}/nouveau`, sx: { m: "0 auto" }, children: ["Cr\u00E9er une nouvelle vid\u00E9o collective ", _jsx(Add, { sx: { ml: 3 } })] }) }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) })), _jsx(ProjectListPageTitle, { children: "Mes projets" }), projects.map((project, index) => (_jsxs(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", sx: {
                    my: 2,
                    p: 1.5,
                    borderRadius: "8px",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                }, children: [_jsx(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", children: _jsxs(Box, { flex: 1, px: 1, children: [_jsx(Typography, { children: project.name }), project.status === ProjectStatus.COMPLETED && (_jsx(Chip, { label: "Vid\u00E9o finalis\u00E9e", color: "primary", size: "small", sx: { mt: 0.5 } }))] }) }), _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", px: 1, children: [project.status === ProjectStatus.ACTIVE && (_jsx(IconButton, { sx: { mr: 2 }, color: "secondary", onClick: () => setDeleteProjectId(project.id), children: _jsx("img", { src: trash, alt: "delete", style: { width: "18px", height: "18px", padding: "3px" } }) })), _jsx(OrangeButton, { component: Link, to: `/${routes.user}/${routes.messages}/${project.id}`, size: "small", color: "secondary", children: "Voir" })] })] }, project.id))), _jsx(DeleteProjectModal, { open: !!deleteProjectId, onClose: handleClose, onDelete: deleteProject }), projects.length === 0 && (_jsx(Typography, { sx: { textAlign: "left" }, children: "Vous n'avez aucune vid\u00E9o collective. Cliquez ci-dessus pour cr\u00E9er votre premi\u00E8re vid\u00E9o !" }))] }));
}
