import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Typography } from '@mui/material';
import { Edit, SwapVert, Visibility } from '@mui/icons-material';
import { InstructionsList, InstructionsListItem } from './ProjectMediaInstructions.styles';
const iconSx = {
    color: "rgba(0, 0, 0, 0.12)",
};
export default function ProjectMediaInstructions({ readonly }) {
    return (_jsxs(InstructionsList, { children: [readonly && (_jsxs(InstructionsListItem, { children: [_jsx(Visibility, { sx: iconSx }), _jsx(Typography, { children: "Voir le contenu" })] })), !readonly && (_jsxs(_Fragment, { children: [_jsxs(InstructionsListItem, { children: [_jsx(Edit, { sx: iconSx }), _jsx(Typography, { children: "Ajouter du texte, raccourcir ou ajuster le volume" })] }), _jsxs(InstructionsListItem, { children: [_jsx(SwapVert, { sx: iconSx }), _jsx(Typography, { children: "Changer l\u2019ordre en drag and drop " })] })] }))] }));
}
