import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
const saveButtonSpacing = 6.5;
export const MediaTextActionsWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: theme.palette.backgrounds.login,
    color: theme.palette.secondary.main,
    position: "relative",
    boxSizing: "border-box",
    paddingRight: theme.spacing(saveButtonSpacing),
}));
export const MediaTextActionsContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
}));
export const ActionButtonContentContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    height: "28px",
}));
export const MediaTextFontSizeInput = styled('input')(({ theme }) => ({
    margin: "0 4px",
    background: "none",
    color: "white",
    fontSize: "14px",
    border: "none",
    outline: "none",
}));
export const MediaTextFontStyleButton = styled((props) => (_jsx(IconButton, { ...props, size: "small", children: _jsxs(ActionButtonContentContainer, { children: [_jsx("span", { className: "bold", children: "B" }), _jsx("span", { className: "italic", children: "I" }), _jsx("span", { className: "underline", children: "S" })] }) })))(({ theme }) => ({
    fontSize: "14px",
    color: theme.palette.secondary.contrastText,
    ['.bold']: {
        fontWeight: "bold",
    },
    ['.italic']: {
        fontStyle: "italic",
        marginLeft: 1,
        marginRight: 2,
    },
    ['.underline']: {
        textDecoration: "underline",
    }
}));
export const ColorBox = styled(Box)(({ theme }) => ({
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: theme.spacing(2),
}));
export const SaveButton = styled('button')(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: theme.spacing(saveButtonSpacing),
    borderRadius: "8px",
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    border: "none",
    outline: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background 0.2s ease-in-out",
    ["&:hover"]: {
        background: theme.palette.primary.main,
    }
}));
