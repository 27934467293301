import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';
import { TabSectionTitle } from './ProjectMusicTab.styles';
import { PROJECT_MUSIC_SELECTION_ID, getAudioFileDuration, uploadCloudinaryAudioFile } from './ProjectMusicTab.utils';
import useApiRequest from '../../../../../hooks/api-request';
import UploadFileInput from '../../../../common/UploadFileInput/UploadFileInput';
// @ts-ignore
import musicNote from '../../../../../../public/music_note.svg';
const MAX_FILE_SIZE = 100 * 1024 * 1024;
export default function ProjectMusicUpload({ project, onMusicFileAdd, onUploadQueueUpdated }) {
    const [cookies] = useCookies();
    const API = useApiRequest();
    const [uploadQueue, setUploadQueue] = useState([]);
    const [uploadError, setUploadError] = useState(false);
    const user = cookies.user;
    useEffect(() => {
        onUploadQueueUpdated(uploadQueue);
    }, [uploadQueue]);
    /**
     * Effect that handles audio files upload queue
     */
    useEffect(() => {
        (async () => {
            if (uploadQueue.length > 0) {
                try {
                    const file = uploadQueue[0];
                    // Upload to cloudinary
                    const url = await uploadCloudinaryAudioFile(file);
                    // Update project with audio file
                    const duration = await getAudioFileDuration(file);
                    const projectMusicFile = {
                        id: uuid(),
                        type: 'audio',
                        file_name: file.name,
                        file_path: url,
                        duration: duration,
                        userId: user.id,
                        userName: user.first_name,
                    };
                    // save audio file to database
                    await API.uploadProjectMusicFile(project.id, projectMusicFile);
                    // add audio file to state
                    onMusicFileAdd(projectMusicFile);
                }
                catch (e) {
                    setUploadError(true);
                }
                finally {
                    setUploadQueue((state) => {
                        const newState = [...state];
                        newState.shift();
                        return newState;
                    });
                }
            }
        })();
    }, [uploadQueue]);
    async function handleUpload(e) {
        if (e.target?.files) {
            // validate files size before adding them to the state
            const files = [];
            for (let file of e.target.files) {
                if (file.size < MAX_FILE_SIZE) {
                    files.push(file);
                }
            }
            setUploadError(false);
            setUploadQueue(files);
            const selectionContainer = document.getElementById(PROJECT_MUSIC_SELECTION_ID);
            if (selectionContainer) {
                selectionContainer.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
    function closeUploadError() {
        setUploadError(false);
    }
    return (_jsxs(Box, { px: 1, py: 2, children: [_jsx(TabSectionTitle, { children: "T\u00E9l\u00E9verser une musique" }), uploadError && (_jsx(Alert, { onClose: closeUploadError, sx: { mb: 1 }, severity: "error", children: "Certains fichiers s\u00E9lectionn\u00E9s n'ont pas pu \u00EAtre ajout\u00E9s." })), _jsx(UploadFileInput, { id: "audio-upload", accept: "audio/mpeg, audio/wav, audio/aac", text: "T\u00E9l\u00E9versez une musique", image: musicNote, onUpload: handleUpload, multiple: true }), _jsx(Typography, { variant: "body2", sx: { mt: 1 }, children: "Formats support\u00E9s : mp3,  wav, aac." }), _jsx(Typography, { variant: "body2", children: "En t\u00E9l\u00E9versant une musique, je confirme ne pas enfreindre les droits d'auteur ni les droits \u00E0 la vie priv\u00E9e d'autrui." })] }));
}
