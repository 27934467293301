import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Page, PageTitle } from '../../../common/styles/page.styles';
import { useNavigate, useParams } from 'react-router-dom';
import useApiRequest from '../../../../hooks/api-request';
import { Box, CircularProgress } from '@mui/material';
import { theme } from '../../../../utils/theme';
import { MediaPreview } from '../MediaPreview';
import MediaTextEditor from './MediaTextEditor';
import MediaTextActions from './MediaTextActions';
import { getMediaTextDefaultValue } from './MediaEditTextPage.utils';
import { routes } from '../../../../App';
import CreatomateMediaPreview from '../../../common/CreatomateMediaPreview/CreatomateMediaPreview';
import { OrangeButton } from '../../../common/styles/buttons.styles';
export default function MediaEditTextPage() {
    const { id, mediaId } = useParams();
    const navigate = useNavigate();
    const API = useApiRequest();
    const [project, setProject] = useState();
    const [media, setMedia] = useState();
    const [isMediaLoaded, setMediaLoaded] = useState(false);
    const [editMode, setEditMode] = useState(false);
    useEffect(() => {
        (async () => {
            if (id && mediaId) {
                try {
                    const response = await API.getProject(id);
                    const project = response.data.project;
                    setProject(project);
                    if (project.files) {
                        const media = project.files.find((media) => media.id === mediaId);
                        setMedia(media);
                    }
                }
                catch {
                    console.error("Error while fetching media");
                }
            }
        })();
    }, [id, mediaId]);
    // Set default media value effect
    useEffect(() => {
        if (media && !media.text) {
            setMedia({
                ...media,
                text: getMediaTextDefaultValue(),
            });
        }
    }, [media]);
    async function updateMediaText(text) {
        if (id && media) {
            try {
                const updatedMedia = Object.assign({}, media, { text });
                // await API.updateProjectFile(id, media.id, updatedMedia);
                setMedia(updatedMedia);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    async function deleteMediaText() {
        if (id && media) {
            try {
                const updatedMedia = Object.assign({}, media, { text: undefined });
                await API.updateProjectFile(id, media.id, updatedMedia);
                navigate(`/${routes.user}/${routes.messages}/${id}/medias/${media.id}`);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    function handleMediaLoaded() {
        setMediaLoaded(true);
    }
    function startEditMode() {
        setEditMode(true);
    }
    async function validateChanges() {
        if (id && media) {
            try {
                await API.updateProjectFile(id, media.id, media);
                // setMedia(updatedMedia);
                setEditMode(false);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    if (!project || !media || !media?.text) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsxs(Page, { sx: { display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }, children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(PageTitle, { children: media.userName }), editMode ? (_jsx(MediaTextEditor, { mediaText: media.text, isMediaLoaded: isMediaLoaded, orientation: project.orientation, onUpdate: updateMediaText, children: _jsx(MediaPreview, { media: media, orientation: project.orientation, onLoad: handleMediaLoaded }) })) : (_jsx(CreatomateMediaPreview, { media: media, orientation: project.orientation }))] }), _jsx(Box, { display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center", py: 2, children: editMode ? (_jsx(_Fragment, { children: _jsx(MediaTextActions, { mediaText: media.text, onUpdate: updateMediaText, onDelete: deleteMediaText, onSave: validateChanges }) })) : (_jsx(OrangeButton, { onClick: startEditMode, children: "Modifier le texte" })) })] }));
}
