import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link, Alert, Box, Typography } from '@mui/material';
import { Stack } from "@mui/system";
import { LoginLoadingButton, LoginLogo, LoginPageWrapper, LoginTextField, LoginTitle, } from './styles/Login.styles';
import { useFormInput } from "../../utils/FormInput";
import { getRequestErrorMessage } from '../../utils/errors';
import useUser from '../../hooks/user';
import useApiRequest from '../../hooks/api-request';
import { routes } from "../../App";
import PasswordResetModal from './PasswordResetModal';
import PasswordField from './PasswordField';
// @ts-ignore
import logo_black from "../../../public/logo_black.svg";
export default function Login() {
    const navigate = useNavigate();
    const email = useFormInput("");
    const password = useFormInput("");
    const API = useApiRequest();
    const { setUserSession } = useUser();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
    async function handleLogin() {
        if (!email.value || !password.value) {
            setError("Email ou mot de passe non renseigné.");
            return;
        }
        try {
            setError(null);
            setLoading(true);
            const { data } = await API.organizerLogin(email.value, password.value);
            setUserSession(data.user, data.token, "organizer");
            navigate(`/${routes.user}/${routes.messages}`);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
        finally {
            setLoading(false);
        }
    }
    function openPasswordResetModal() {
        setPasswordResetModalOpen(true);
    }
    function closePasswordResetModal() {
        setPasswordResetModalOpen(false);
    }
    return (_jsxs(LoginPageWrapper, { maxWidth: false, children: [_jsx(LoginLogo, { src: logo_black }), _jsx(LoginTitle, { children: "Se connecter" }), _jsx(Box, { width: { xs: "90%", md: "50%" }, margin: "0 auto", children: _jsxs(Stack, { display: "flex", alignItems: "stretch", spacing: 2, children: [_jsx(LoginTextField, { id: "outlined-basic", label: "Adresse email", variant: "standard", type: "email", ...email }), _jsxs(Box, { textAlign: "left", children: [_jsx(PasswordField, { label: "Mot de passe", ...password }), _jsx(Typography, { onClick: openPasswordResetModal, mt: 2, variant: "body2", display: "inline-block", sx: {
                                        "&:hover": {
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                    }, children: "Mot de passe oubli\u00E9 ?" })] }), error && (_jsx(Alert, { severity: "error", color: "info", children: error })), _jsx(Box, { px: 2, p: 2, children: _jsxs(Typography, { variant: "body2", textAlign: "center", children: [_jsx("span", { children: "Pas encore de compte ?" }), _jsx(Link, { component: RouterLink, sx: { color: "black", ml: 0.5 }, to: "/signup", children: "Inscription" })] }) }), _jsx(Box, { pt: 2, children: _jsx(LoginLoadingButton, { variant: "contained", sx: { cursor: "pointer", maxWidth: "none" }, loading: loading, onClick: handleLogin, children: "Connexion" }) })] }) }), _jsx(PasswordResetModal, { open: passwordResetModalOpen, onClose: closePasswordResetModal })] }));
}
