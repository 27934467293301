import { useCookies } from "react-cookie";
const COOKIES_MAX_AGE = 86400 * 14; // 14 days
export default function useUser() {
    const [cookies, setCookie, removeCookie] = useCookies();
    function setUserSession(user, token, role) {
        setCookie("token", token, { maxAge: COOKIES_MAX_AGE, path: "/" });
        setCookie("user", JSON.stringify(user), { maxAge: COOKIES_MAX_AGE, path: "/" });
        setCookie("role", role, { maxAge: COOKIES_MAX_AGE, path: "/" });
    }
    function removeUserSession() {
        removeCookie("token", { path: "/" });
        removeCookie("user", { path: "/" });
        removeCookie("role", { path: "/" });
    }
    return {
        user: cookies.user,
        token: cookies.token,
        role: cookies.role,
        setUserSession,
        removeUserSession,
    };
}
