import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Typography, Box } from '@mui/material';
import { ProjectStatus } from '../../../models/project';
import { SecondaryButton } from '../../common/styles/buttons.styles';
import { routes } from "../../../App";
import { Page, PageTitle } from '../../common/styles/page.styles';
import { useProject } from '../../../contexts/project';
import InfoTooltip from '../../common/tooltips/InfoTooltip';
import { format } from 'date-fns';
export default function ProjectContributorDashboardPage() {
    const { project, isProjectLoading, updateProject } = useProject();
    const { id } = useParams();
    const [cookies] = useCookies();
    const role = cookies.role;
    if (isProjectLoading || !project) {
        return null;
    }
    const readonly = project.status !== ProjectStatus.ACTIVE;
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: project.name }), _jsxs(Box, { py: 2, children: [_jsx(Typography, { fontWeight: "bold", children: "Message de l\u2019organisateur\u00B7trice" }), _jsx(Box, { sx: {
                            mt: 1,
                            p: 2,
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                            textAlign: "left",
                            borderRadius: "8px",
                        }, children: _jsx(Typography, { children: project.description }) })] }), _jsxs(Box, { py: 2, display: "flex", flexDirection: "row", alignItems: "center", children: [_jsxs(Typography, { fontWeight: "bold", children: [_jsx("span", { children: "Orientation" }), _jsx(InfoTooltip, { children: _jsx("span", { children: "Pour un meilleur rendu, nous vous conseillons d'ajouter des contenus dans l'orientation choisie par l'organisateur\u00B7trice" }) }), _jsx("span", { children: " : " })] }), _jsx(Typography, { sx: { ml: 1 }, children: project.orientation })] }), _jsxs(Box, { py: 2, children: [_jsx(Typography, { fontWeight: "bold", children: "Date limite d\u2019envoi des contenus" }), _jsx(Typography, { children: project.endDate ? format(new Date(project.endDate), "dd/MM/yyyy") : "-" })] }), !readonly && (_jsx(Box, { pt: 6, pb: 2, textAlign: "center", children: _jsx(SecondaryButton, { component: Link, to: `/${routes.contributor}/${routes.messages}/${id}/media/ajouter`, children: "Ajouter un contenu" }) }))] }));
}
