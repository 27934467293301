const watermarkPortraitLogoSrc = "https://res.cloudinary.com/deiusslow/image/upload/v1690203317/logos/watermark_portrait.png";
const watermarkLandscapeLogoSrc = "https://res.cloudinary.com/deiusslow/image/upload/v1690203317/logos/watermark_landscape.png";
export const LANDSCAPE_WIDTH = 1280;
export const LANDSCAPE_HEIGHT = 720;
export const PORTRAIT_WIDTH = 720;
export const PORTRAIT_HEIGHT = 1280;
// const JINGLE_IMAGE_DURATION = 0.1;
export const JINGLE_DURATION = 3;
export const ANIMATION_DURATION = 1;
export const DEFAULT_MUSIC_VOLUME = 4;
function mediaFilesDurationReducer(total, media) {
    const mediaDuration = typeof media.startAt === "number" && typeof media.endAt === "number"
        ? media.endAt - media.startAt
        : media.duration;
    return total + mediaDuration;
}
function audioFilesDurationReducer(total, file) {
    return total + file.duration;
}
function createWatermarkJson(orientation) {
    return {
        track: 2,
        source: orientation === "portrait" ? watermarkPortraitLogoSrc : watermarkLandscapeLogoSrc,
        type: 'image',
        height: '100vh',
        width: '100vw',
    };
}
function createEndBlackScreen() {
    return {
        track: 1,
        type: "shape",
        shape: "rectangle",
        fill_color: "#000000",
        width: "100vw",
        height: "100vh",
        animations: [
            {
                time: "start",
                duration: `${ANIMATION_DURATION * 2} s`,
                type: "fade",
                transition: true,
            },
        ],
    };
}
function createJingleVideoJson(orientation) {
    return {
        track: 1,
        type: "video",
        source: orientation === "portrait"
            ? "https://res.cloudinary.com/deiusslow/video/upload/v1691001241/jingles/new_jingle_9_16.mp4"
            : "https://res.cloudinary.com/deiusslow/video/upload/v1691001241/jingles/new_jingle_16_9.mp4",
        fit: "contain",
        ...(orientation === "portrait" ? { width: PORTRAIT_WIDTH } : {}),
        ...(orientation === "paysage" ? { height: LANDSCAPE_HEIGHT } : {}),
    };
}
function createTextJson(mediaText) {
    return {
        track: 2,
        type: "text",
        text: mediaText.text,
        width: `${mediaText.width}%`,
        height: `${mediaText.height}%`,
        fill_color: mediaText.textColor,
        x: `${mediaText.x + (mediaText.width / 2)}%`,
        y: `${mediaText.y + (mediaText.height / 2)}%`,
        x_alignment: `${mediaText.textAlign === "left" ? 0 : mediaText.textAlign === "center" ? 50 : 100}%`,
        y_alignment: `0%`,
        font_family: mediaText.fontFamily,
        font_size: `${mediaText.fontSize * mediaText.fontSizeScaleFactor}px`,
        text_wrap: true,
        text_clip: false,
        background_color: mediaText.textBackgroundColor === "transparent" ? null : mediaText.textBackgroundColor,
        background_x_padding: "5%",
        background_y_padding: "5%",
        background_border_radius: "0%",
        animations: [
            {
                time: "start",
                duration: `${ANIMATION_DURATION} s`,
                type: "fade",
                transition: true,
            },
        ],
    };
}
function createImageJson(media, orientation) {
    return {
        track: 1,
        type: media.type,
        source: media.preview_file_path ? media.preview_file_path : media.file_path.replace("http://", "https://"),
        fit: "contain",
        duration: media.duration + ANIMATION_DURATION,
        ...(orientation === "portrait" ? { width: PORTRAIT_WIDTH } : {}),
        ...(orientation === "paysage" ? { height: LANDSCAPE_HEIGHT } : {}),
        animations: [
            {
                time: "start",
                duration: `${ANIMATION_DURATION} s`,
                type: "fade",
                transition: true,
            },
        ],
    };
}
function createVideoJson(media, orientation) {
    const volume = typeof media.volume === "number" ? media.volume : 10;
    return {
        track: 1,
        type: media.type,
        source: media.preview_file_path ? media.preview_file_path : media.file_path.replace("http://", "https://"),
        fit: "contain",
        duration: media.duration + ANIMATION_DURATION,
        volume: `${volume * 10}%`,
        ...(orientation === "portrait" ? { width: PORTRAIT_WIDTH } : {}),
        ...(orientation === "paysage" ? { height: LANDSCAPE_HEIGHT } : {}),
        ...(typeof media.startAt === "number" && typeof media.endAt === "number" ? {
            trim_start: media.startAt,
            trim_duration: media.endAt - media.startAt,
        } : {}),
        animations: [
            {
                time: "start",
                duration: `${ANIMATION_DURATION} s`,
                type: "fade",
                transition: true,
            },
        ],
    };
}
function createAudioJson(file, volume, isLast) {
    return {
        track: 2,
        type: "audio",
        source: file.file_path.replace("http://", "https://"),
        volume: `${volume * 10}%`,
        duration: null,
        ...(isLast ? {
            loop: true,
            audio_fade_out: `${ANIMATION_DURATION * 2} s`
        } : {}), // "1 s",
    };
}
function createCompositionJson(elements) {
    return {
        track: 1,
        type: "composition",
        elements,
    };
}
export function buildPreviewJson(project) {
    const orientation = project.orientation;
    const elements = [];
    // add jingle
    elements.push(createJingleVideoJson(orientation));
    // video / image / media with text composition
    const mediaElements = project.files.reduce((list, media) => {
        // ensure HTTPS
        const path = new URL(media.file_path);
        if (path.protocol === "http:") {
            path.protocol = "https:";
            media.file_path = path.toString();
        }
        let elements = [];
        // add media element
        if (media.type === "video") {
            elements.push(createVideoJson(media, orientation));
        }
        else if (media.type === "image") {
            elements.push(createImageJson(media, orientation));
        }
        // add text element if defined
        if (media.text) {
            elements.push(createTextJson(media.text));
        }
        // add element to the list, the element can be:
        // - the media element alone
        // - a composition element with media and text
        if (elements.length > 1) {
            list.push(createCompositionJson(elements));
        }
        else {
            list.push(elements[0]);
        }
        return list;
    }, []);
    //  audio elements
    let audioElements = [];
    if (project.music && project.music.files.length > 0) {
        const { files, volume = DEFAULT_MUSIC_VOLUME } = project.music;
        audioElements = files.map((file, index) => createAudioJson(file, volume, index === files.length - 1));
        // const isSingleAudio = files.length === 1;
        // const musicFilesDuration = files.reduce(audioFilesDurationReducer, 0);
        // const mediasDuration = project.files.reduce(mediaFilesDurationReducer, 0);
        //
        // // Audio elements is either constructed when a list of many files is shorted than the final video
        // // Or with one audio file playing in loop
        // // Or with many files with a total duration greater than the final video
        // if (!isSingleAudio && musicFilesDuration < mediasDuration) {
        //   let audioFiles = [...files];
        //   let audioDuration = musicFilesDuration;
        //
        //   // ensure the audio duration is at least the media duration
        //   while (audioDuration < mediasDuration) {
        //     audioFiles = [...audioFiles, ...files];
        //     audioDuration += musicFilesDuration;
        //   }
        //
        //   audioElements = audioFiles.map((file: ProjectMusicFile, index: number) => createAudioJson(file, volume, index === audioFiles.length - 1));
        // } else {
        //   audioElements = files.map((file: ProjectMusicFile, index: number) => createAudioJson(file, volume, index === files.length - 1));
        // }
    }
    // composition with media and audio
    elements.push(createCompositionJson([
        ...mediaElements,
        ...audioElements,
    ]));
    // add end black shape
    elements.push(createEndBlackScreen());
    // add watermark
    elements.push(createWatermarkJson(orientation));
    return {
        output_format: "mp4",
        width: orientation === "paysage" ? LANDSCAPE_WIDTH : PORTRAIT_WIDTH,
        height: orientation === "paysage" ? LANDSCAPE_HEIGHT : PORTRAIT_HEIGHT,
        elements: elements,
    };
}
export function buildMediaPreview(media, orientation, ignoreText) {
    let elements = [];
    if (media.type === "video") {
        elements.push(createVideoJson(media, orientation));
    }
    if (media.type === "image") {
        elements.push(createImageJson(media, orientation));
    }
    if (!ignoreText && media.text) {
        elements.push(createTextJson(media.text));
    }
    if (elements.length > 1) {
        elements = [createCompositionJson(elements)];
    }
    return {
        output_format: "mp4",
        width: orientation === "paysage" ? LANDSCAPE_WIDTH : PORTRAIT_WIDTH,
        height: orientation === "paysage" ? LANDSCAPE_HEIGHT : PORTRAIT_HEIGHT,
        elements: elements,
    };
}
