import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Page, PageTitle } from '../../../common/styles/page.styles';
import { useNavigate, useParams } from 'react-router-dom';
import useApiRequest from '../../../../hooks/api-request';
import { Box, CircularProgress } from '@mui/material';
import { theme } from '../../../../utils/theme';
import { useProject } from '../../../../contexts/project';
import { routes } from '../../../../App';
import VideoDurationSlider from './VideoDurationSlider';
import CreatomateMediaPreview from '../../../common/CreatomateMediaPreview/CreatomateMediaPreview';
import { ActionsWrapper, ActionsContainer } from './MediaEditDurationPage.styles';
let prevMedia;
export default function MediaEditDurationPage() {
    const { id, mediaId } = useParams();
    const navigate = useNavigate();
    const { project, isProjectLoading } = useProject();
    const API = useApiRequest();
    const [media, setMedia] = useState();
    useEffect(() => {
        (async () => {
            if (project && mediaId) {
                try {
                    if (project.files) {
                        const media = project.files.find((media) => media.id === mediaId);
                        if (media.type !== "video") {
                            navigate(`/${routes.user}/${routes.messages}/${project.id}/edit/${media.id}`);
                        }
                        prevMedia = media;
                        setMedia(media);
                    }
                }
                catch {
                    console.error("Error while fetching media");
                }
            }
        })();
    }, [project, mediaId]);
    useEffect(() => {
        (async () => {
            if (media && (media?.startAt !== prevMedia?.startAt || media?.endAt !== prevMedia?.endAt)) {
                await saveProjectMedia();
            }
        })();
    }, [media?.startAt, media?.endAt]);
    async function saveProjectMedia() {
        if (id && mediaId && media) {
            try {
                const response = await API.updateProjectFile(id, mediaId, media);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    function updateMediaDuration(startAt, endAt) {
        if (media) {
            setMedia({ ...media, startAt, endAt });
        }
    }
    if (isProjectLoading || !project || !media) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsxs(Page, { sx: { display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }, children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(PageTitle, { children: media.userName }), _jsx(CreatomateMediaPreview, { media: media, orientation: project.orientation })] }), _jsx(ActionsWrapper, { children: _jsx(ActionsContainer, { children: _jsx(VideoDurationSlider, { duration: media.duration, startAt: media.startAt, endAt: media.endAt, onChange: updateMediaDuration }) }) })] }));
}
