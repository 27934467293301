import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import validator from 'validator';
import { Modal, Box, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ModalContent, ModalText, ModalTitle, ModalWrapper, ModalCloseButton } from '../common/styles/modals.styles';
import { SecondaryWhiteButton } from '../common/styles/buttons.styles';
import { useFormInput } from '../../utils/FormInput';
import useApiRequest from '../../hooks/api-request';
import { WhiteTextField } from './styles/Login.styles';
export default function PasswordResetModal({ open, onClose }) {
    const email = useFormInput("");
    const API = useApiRequest();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    async function handlePasswordReset() {
        try {
            setSuccess(false);
            setError("");
            setLoading(true);
            const response = await API.organizerPasswordResetRequest(email.value);
            console.log(response);
            setSuccess(true);
        }
        catch (error) {
            setError("L'email de récupération du mot de passe n'a pas pu être envoyé, veuillez ré-essayer.");
        }
        finally {
            setLoading(false);
        }
    }
    return (_jsx(Modal, { open: open, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", children: _jsx(ModalWrapper, { children: _jsxs(ModalContent, { display: "flex", flexDirection: "column", justifyContent: "center", children: [_jsx(ModalCloseButton, { onClick: onClose }), _jsx(ModalTitle, { sx: { pt: 0 }, children: "Mot de passe oubli\u00E9 ?" }), success ? (_jsxs(_Fragment, { children: [_jsx(ModalText, { sx: { mt: 2 }, children: "Si l'adresse email renseign\u00E9e est associ\u00E9 \u00E0 un compte existant, vous recevrez dans quelques instants un email afin de r\u00E9initialiser le mot de passe." }), _jsx(Stack, { mt: 2, direction: { xs: "column", sm: "row-reverse" }, spacing: 2, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "center", children: _jsx(SecondaryWhiteButton, { sx: { maxWidth: "none" }, onClick: onClose, children: "Fermer" }) })] })) : (_jsxs(_Fragment, { children: [_jsx(ModalText, { sx: { mt: 2 }, children: "Saisissez votre email, nous vous enverrons un email pour r\u00E9cup\u00E9rer votre mot de passe." }), _jsx(Box, { py: 2, children: _jsx(WhiteTextField, { variant: "standard", label: "Adresse email", value: email.value, onChange: email.onChange }) }), _jsx(Stack, { mt: 2, direction: { xs: "column", sm: "row-reverse" }, spacing: 2, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "center", children: _jsx(SecondaryWhiteButton, { component: LoadingButton, onClick: handlePasswordReset, sx: { maxWidth: "none" }, disabled: !email.value || !validator.isEmail(email.value), loading: loading, children: "Envoyer un email de r\u00E9cup\u00E9ration" }) })] }))] }) }) }));
}
