import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { TextField } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Alert } from "@mui/material";
import { Stack } from "@mui/system";
import { GreenButton, OrangeButton } from "../common/styles/buttons.styles";
import { routes } from "../../App";
import { useFormInput } from "../../utils/FormInput";
import { Page, PageTitle } from '../common/styles/page.styles';
import { ERROR_PASSWORD } from "../common/constants/errors";
import { getRequestErrorMessage } from '../../utils/errors';
function UpdateUser() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const user = cookies.user;
    const navigate = useNavigate();
    const firstName = useFormInput(user.first_name);
    const email = useFormInput(user.email);
    const password = useFormInput("");
    const newPassword = useFormInput("");
    const [error, setError] = useState(null);
    const handleLogout = () => {
        removeCookie("token");
        removeCookie("user");
        navigate(`/login`);
    };
    const handleLogin = async () => {
        if (!firstName.value || !email.value || !password.value) {
            setError("Merci de rentrer toutes les nouvelles informations");
            return;
        }
        if (password.value !== newPassword.value) {
            setError(ERROR_PASSWORD);
            return;
        }
        setError(null);
        return axios
            .post(`${process.env.REACT_APP_API_URL}/organizer/${user.id}/update`, {
            username: firstName.value,
            email: email.value,
            password: password.value,
        })
            .then((response) => {
            setCookie("token", response.data.token, { maxAge: 86400 * 14 });
            setCookie("user", JSON.stringify(response.data.user), {
                maxAge: 86400 * 14,
            });
            setCookie("role", "organizer", { maxAge: 86400 * 14 });
            navigate(`/${routes.user}/${routes.messages}`);
        })
            .catch((error) => {
            const message = getRequestErrorMessage(error);
            setError(message);
        });
    };
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: "Modifier mes informations" }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) })), _jsx(Stack, { sx: { p: 2 }, children: _jsx(TextField, { id: "outlined-basic", label: "Pr\u00E9nom", variant: "standard", type: "text", name: "firstName", ...firstName }) }), _jsx(Stack, { sx: { p: 2 }, children: _jsx(TextField, { id: "outlined-basic", label: "Adresse email", variant: "standard", type: "email", name: "email", ...email }) }), _jsx(Stack, { sx: { p: 2 }, children: _jsx(TextField, { id: "outlined-basic", label: "Nouveau mot de passe", variant: "standard", type: "password", ...password }) }), _jsx(Stack, { sx: { p: 2 }, children: _jsx(TextField, { id: "outlined-basic", label: "Confirmer le nouveau mot de passe", variant: "standard", type: "password", ...newPassword }) }), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsx(GreenButton, { onClick: handleLogin, children: "Enregistrer les modifications" }) }), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsx(OrangeButton, { onClick: handleLogout, children: "Se d\u00E9connecter" }) })] }));
}
export default UpdateUser;
