import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Box } from '@mui/material';
import { EmptyTypography } from '../ProjectMusicTab.styles';
import ProjectMusicFileDeleteModal from './ProjectMusicFileDeleteModal';
import { ProjectMusicFileListItem } from './ProjectMusicFileListItem';
export default function ProjectMusicFilesList({ files, playingFileId, isPlayingMusic, readonly, onMusicPlay, onMusicPause, onUpdateFiles, onDeleteFile }) {
    const [deleteFile, setDeleteFile] = useState();
    async function deleteMusicFile() {
        if (deleteFile) {
            await onDeleteFile(deleteFile.id);
            setDeleteFile(undefined);
        }
    }
    function openDeleteModal(file) {
        setDeleteFile(file);
    }
    function closeDeleteModal() {
        setDeleteFile(undefined);
    }
    function reorder(startIndex, endIndex) {
        const result = Array.from(files);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const updatedMusicFiles = reorder(result.source.index, result.destination.index);
        return onUpdateFiles(updatedMusicFiles);
    }
    if (!files?.length) {
        return (_jsx(EmptyTypography, { children: "Vous n\u2019avez pas encore ajout\u00E9 de musique." }));
    }
    return (_jsxs(Box, { children: [_jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Droppable, { droppableId: "music", children: (provided) => (_jsxs(Box, { display: "flex", flexDirection: "column", ref: provided.innerRef, ...provided.droppableProps, children: [files.map((file, index) => (_jsx(ProjectMusicFileListItem, { file: file, index: index, readonly: readonly, playing: isPlayingMusic && playingFileId === file.id, onMusicPlay: onMusicPlay, onMusicPause: onMusicPause, onDelete: openDeleteModal }, file.id))), provided.placeholder] })) }) }), _jsx(ProjectMusicFileDeleteModal, { file: deleteFile, onDelete: deleteMusicFile, onClose: closeDeleteModal })] }));
}
