import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from "axios";
import { Box, IconButton, Typography } from '@mui/material';
import { theme } from "../../../utils/theme";
// @ts-ignore
import download from "../../../../public/download.svg";
export default function DownloadButton({ project }) {
    async function handleDownload() {
        try {
            const response = await axios({
                url: project?.finalVideo,
                method: "GET",
                responseType: "blob",
            });
            const urlObject = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = urlObject;
            link.download = `${project.id}.mp4`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (e) {
            console.error("Error while downloading final video", e);
        }
    }
    return (_jsxs(Box, { textAlign: "center", width: 100, children: [_jsx(IconButton, { sx: { height: "fit-content", mb: 1, p: 0 }, onClick: handleDownload, children: _jsx("img", { src: download, alt: "download", style: {
                        width: theme.typography.pxToRem(35),
                        height: theme.typography.pxToRem(35),
                        color: theme.palette.primary.main,
                    } }) }), _jsx(Typography, { fontSize: 12, children: "T\u00E9l\u00E9charger" })] }));
}
