import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useCallback } from 'react';
import { Rnd } from 'react-rnd';
import throttle from 'lodash/throttle';
import { MediaID } from '../MediaPreview';
import { percentageToPx, pxToPercentage } from '../MediaEdit.utils';
import { LANDSCAPE_WIDTH, PORTRAIT_WIDTH } from '../../utils/creatomate';
const resizeHandleSize = 8;
const resizeHandleCommonStyles = {
    width: `${resizeHandleSize}px`,
    height: `${resizeHandleSize}px`,
    background: "white",
    borderRadius: `${resizeHandleSize}px`,
};
const fontFamiliesStyle = {
    "Montserrat": "'Montserrat', sans-serif",
    "Oswald": "'Oswald', sans-serif",
    "Source Code Pro": "'Source Code Pro', monospace",
    "EB Garamond": "'EB Garamond', serif",
    "Dancing Script": "'Dancing Script', cursive",
    "Courier Prime": "'Courier Prime', monospace",
    "Comic Mono": "'Comic Mono', monospace",
    "Passion One": "'Passion One', cursive",
};
export default function MediaTextEditor({ children, mediaText, isMediaLoaded, orientation, onUpdate }) {
    const editorRef = React.useRef(null);
    const onUpdatedThrottled = useCallback(throttle(onUpdate, 3000), []);
    // can be the media node itself or its parent (in case of image)
    const [mediaContainerNode, setMediaContainerNode] = useState();
    const [text, setText] = useState(mediaText.text);
    const [defaultValues, setDefaultValues] = useState({
        x: 0,
        y: 0,
        width: "auto",
        height: "auto",
    });
    /**
     * Initialize default values for the text placed over the media
     * Default values are calculted based on the media size on the screen
     * That's why we need to wait for the media to be loaded to calculate the values
     */
    useEffect(() => {
        if (mediaText && isMediaLoaded) {
            const containerNode = document.getElementById(MediaID);
            const left = percentageToPx(mediaText.x, containerNode.offsetWidth);
            const top = percentageToPx(mediaText.y, containerNode.offsetHeight);
            setDefaultValues({
                x: left,
                y: top,
                width: `${mediaText.width}%`,
                height: `${mediaText.height}%`,
            });
            // Compute font size scale factor, this is required by creatomate
            // in order to scale the font size in the final render
            const finalVideoWidth = orientation === "paysage" ? LANDSCAPE_WIDTH : PORTRAIT_WIDTH;
            const fontSizeScaleFactor = finalVideoWidth / containerNode.offsetWidth;
            if (mediaText.fontSizeScaleFactor !== fontSizeScaleFactor) {
                onUpdate({
                    ...mediaText,
                    fontSizeScaleFactor,
                });
            }
            setMediaContainerNode(containerNode);
        }
    }, [mediaText, isMediaLoaded]);
    function handleDragStop(event, data) {
        if (mediaText && mediaContainerNode) {
            onUpdate({
                ...mediaText,
                x: pxToPercentage(data.x, mediaContainerNode.offsetWidth),
                y: pxToPercentage(data.y, mediaContainerNode.offsetHeight),
            });
        }
    }
    function handleResizeStop(event, direction, ref) {
        if (mediaText && mediaContainerNode) {
            let newHeight = ref.offsetHeight;
            // ensure the container height is not smaller than the textarea content
            const textArea = ref.getElementsByTagName('textarea')[0];
            if (newHeight < textArea.scrollHeight) {
                newHeight = textArea.scrollHeight;
                editorRef?.current?.updateSize({ width: ref.offsetWidth, height: textArea.scrollHeight });
            }
            onUpdate({
                ...mediaText,
                width: pxToPercentage(ref.offsetWidth, mediaContainerNode.offsetWidth),
                height: pxToPercentage(newHeight, mediaContainerNode.offsetHeight),
            });
        }
    }
    function handleTextareaChange(event) {
        if (mediaText && text !== event.target.value) {
            // Update the container height if smaller than the textarea content
            if (event.target.offsetHeight < event.target.scrollHeight) {
                editorRef?.current?.updateSize({ width: event.target.offsetWidth + 2, height: event.target.scrollHeight });
            }
            setText(event.target.value);
            onUpdatedThrottled({
                ...mediaText,
                text: event.target.value,
            });
        }
    }
    /**
     * The textarea within rnd is not focusable on mobile devices
     * We need to force the focused using the TouchEnd event
     */
    function handleMobileFocus(event) {
        event.persist();
        event.currentTarget.focus();
        event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length);
    }
    return (_jsxs("div", { style: {
            position: "relative",
            width: mediaContainerNode?.offsetWidth ? `${mediaContainerNode?.offsetWidth}px` : "auto",
            maxWidth: mediaContainerNode?.offsetWidth ? `${mediaContainerNode?.offsetWidth}px` : "auto",
            height: mediaContainerNode?.offsetHeight ? `${mediaContainerNode?.offsetHeight}px` : "auto",
            maxHeight: mediaContainerNode?.offsetHeight ? `${mediaContainerNode?.offsetHeight}px` : "auto",
        }, children: [children, mediaText && isMediaLoaded && mediaContainerNode && (_jsx(Rnd, { ref: editorRef, bounds: "parent", maxWidth: mediaContainerNode.offsetWidth, maxHeight: mediaContainerNode.offsetHeight, default: defaultValues, style: {
                    border: "1px solid white",
                }, onDragStop: handleDragStop, onResizeStop: handleResizeStop, enableUserSelectHack: false, resizeHandleStyles: {
                    top: {
                        top: `-${resizeHandleSize / 2}px`,
                        left: `calc(50% - ${resizeHandleSize / 2}px)`,
                        ...resizeHandleCommonStyles,
                    },
                    topLeft: {
                        top: `-${resizeHandleSize / 2}px`,
                        left: `-${resizeHandleSize / 2}px`,
                        ...resizeHandleCommonStyles,
                    },
                    right: {
                        top: `calc(50% - ${resizeHandleSize / 2}px)`,
                        right: `-${resizeHandleSize / 2}px`,
                        ...resizeHandleCommonStyles,
                    },
                    bottomLeft: {
                        bottom: `-${resizeHandleSize / 2}px`,
                        left: `-${resizeHandleSize / 2}px`,
                        ...resizeHandleCommonStyles,
                    },
                    bottom: {
                        bottom: `-${resizeHandleSize / 2}px`,
                        left: `calc(50% - ${resizeHandleSize / 2}px)`,
                        ...resizeHandleCommonStyles,
                    },
                    bottomRight: {
                        bottom: `-${resizeHandleSize / 2}px`,
                        right: `-${resizeHandleSize / 2}px`,
                        ...resizeHandleCommonStyles,
                    },
                    left: {
                        top: `calc(50% - ${resizeHandleSize / 2}px)`,
                        left: `-${resizeHandleSize / 2}px`,
                        ...resizeHandleCommonStyles,
                    },
                    topRight: {
                        top: `-${resizeHandleSize / 2}px`,
                        right: `-${resizeHandleSize / 2}px`,
                        ...resizeHandleCommonStyles,
                    },
                }, children: _jsx("textarea", { value: text, onChange: handleTextareaChange, onTouchEnd: handleMobileFocus, onResize: () => console.log('Resized textarea'), style: {
                        width: "100%",
                        height: "100%",
                        maxWidth: mediaContainerNode.offsetWidth,
                        maxHeight: mediaContainerNode.offsetHeight,
                        fontSize: `${mediaText.fontSize}px`,
                        fontFamily: fontFamiliesStyle[mediaText.fontFamily],
                        textAlign: mediaText.textAlign,
                        color: mediaText.textColor,
                        backgroundColor: mediaText.textBackgroundColor,
                        fontWeight: mediaText.fontStyle === "bold" ? "bold" : "normal",
                        fontStyle: mediaText.fontStyle === "italic" ? "italic" : "normal",
                        textDecoration: mediaText.fontStyle === "underline" ? "underline" : "none",
                        outlineColor: "transparent",
                        border: "none",
                        resize: "none",
                        overflow: "hidden",
                        boxSizing: "border-box",
                        padding: "2px",
                    } }) }))] }));
}
