import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import validator from 'validator';
import { Box, TextField, Typography } from '@mui/material';
import { GreenButton } from '../../common/styles/buttons.styles';
import { BlockBox, BlockBoxText, BlockBoxTitle } from '../../common/styles/boxes.styles';
import InfoTooltip from '../../common/tooltips/InfoTooltip';
import { getRequestErrorMessage } from '../../../utils/errors';
export default function InviteEmailForm({ onEmailsAdd, onEmailsError }) {
    const { id } = useParams();
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            email: "",
        },
    });
    async function onSubmit(values) {
        try {
            // Prepare emails
            const emails = values.email.split(",")
                .map((email) => email.trim())
                .filter((email) => validator.isEmail(email));
            // Add contributors to project
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/project/${id}/sharing`, { emails });
            // Update state and send invites
            onEmailsAdd(response.data.emails);
            // Reset form
            reset();
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            onEmailsError(message);
        }
    }
    return (_jsxs(BlockBox, { children: [_jsx(BlockBoxTitle, { children: "Invitation et rappels automatiques par mail" }), _jsxs(BlockBoxText, { children: ["On s\u2019occupe des rappels !", _jsx(InfoTooltip, { children: _jsx(Typography, { children: "Les invit\u00E9\u00B7es seront notifi\u00E9\u00B7es par email maintenant, \u00E0 la moiti\u00E9 du parcours, la veille et le jour-m\u00EAme de la date limite." }) }), _jsx("br", {}), "Pour ajouter plusieurs mails \u00E0 la fois, s\u00E9parez-les d\u2019une virgule."] }), _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs(Box, { sx: { mt: 1, display: 'flex', alignItems: 'flex-end' }, children: [_jsx(Box, { sx: { flex: 1 }, children: _jsx(Controller, { name: "email", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Ajouter les emails", variant: "standard", type: "text", ...field })) }) }), _jsx(Box, { pl: 2, children: _jsx(GreenButton, { type: "submit", children: "Envoyer" }) })] }) })] }));
}
