import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BlockBox, BlockBoxTitle } from '../../common/styles/boxes.styles';
import { Box, Typography, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
// @ts-ignore
import check_ok from '../../../../public/check_ok.svg';
export default function ContributorsEmailsList({ emails, onDeleteInvite }) {
    const theme = useTheme();
    return (_jsxs(BlockBox, { children: [_jsx(BlockBoxTitle, { children: "Liste des invit\u00E9\u00B7es" }), _jsxs(Box, { children: [emails.map((email) => (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', mb: 1, pl: 2.5 }, children: [_jsx(Typography, { sx: { display: "list-item" }, children: email.email }), email.sent && (_jsx("img", { alt: "check_ok", src: check_ok, style: {
                                    paddingLeft: theme.spacing(1),
                                } })), !email.sent && (_jsx(Close, { sx: { cursor: "pointer" }, onClick: () => onDeleteInvite(email) }))] }, email.email))), emails.length === 0 && (_jsx(Typography, { sx: { fontStyle: "italic", color: theme.palette.text.disabled }, children: "Vous n\u2019avez pas encore envoy\u00E9 d\u2019invitation par email." }))] })] }));
}
