import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { Box, useTheme } from '@mui/material';
import { routes } from "../../App";
import { OnboardingContainer, OnboardingImage, OnboardingLogo, OnboardingNextButton, OnboardingSkipButton, OnboardingStep, OnboardingStepNumber, OnboardingStepsContainer, OnboardingText, OnboardingTitle, OnboardingWrapper } from './ContributorOnboarding.styles';
// @ts-ignore
import LOGO_BLANC from "./assets/img/LOGO_BLANC.png";
// @ts-ignore
import step1Image1 from "./assets/step-1-image-1.png";
// @ts-ignore
import step1Image2 from "./assets/step-1-image-2.png";
// @ts-ignore
import step2Image from "./assets/step-2-image.png";
export default function ContributorOnboarding() {
    const navigate = useNavigate();
    const { projectId, step } = useParams();
    const theme = useTheme();
    const [cookies, setCookie, removeCookie] = useCookies();
    function handleSkip() {
        setOnboardingSeenCookie();
        navigate(`/${routes.contributor}/${routes.messages}/${projectId}/login`);
    }
    function setOnboardingSeenCookie() {
        setCookie("hasSeenContributorOnboarding", true, {
            path: "/",
            maxAge: 86400 * 14,
        });
    }
    if (step === "0") {
        return (_jsxs(OnboardingWrapper, { step: step, children: [_jsx(OnboardingSkipButton, { onClick: handleSkip, children: "Passer" }), _jsxs(OnboardingContainer, { children: [_jsx(OnboardingLogo, { alt: "logo", src: LOGO_BLANC }), _jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(OnboardingTitle, { children: "Quoi de mieux que d'envoyer du love ?" }), _jsx(OnboardingText, { sx: { mt: 2 }, children: "Vous \u00EAtes invit\u00E9\u00B7e \u00E0 participer \u00E0 une vid\u00E9o collective m\u00E9morable !" }), _jsx(OnboardingNextButton, { component: Link, to: `/${routes.onboarding}/${projectId}/1`, onClick: setOnboardingSeenCookie, children: "Suivant" })] })] })] }));
    }
    if (step === "1") {
        return (_jsxs(OnboardingWrapper, { step: step, children: [_jsx(OnboardingSkipButton, { onClick: handleSkip, children: "Passer" }), _jsxs(OnboardingStepsContainer, { children: [_jsx(OnboardingStep, { active: true }), _jsx(OnboardingStep, { active: false })] }), _jsxs(OnboardingContainer, { children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(OnboardingStepNumber, { children: "1" }), _jsx(OnboardingTitle, { children: "Envoyez vos contenus" }), _jsx(OnboardingText, { sx: { mt: 4 }, children: "Chacun envoie ses propres photos et vid\u00E9os en un rien de temps. C'est le moment d'envoyer du love !" })] }), _jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", children: [_jsx(OnboardingImage, { alt: "step 1 image 1", sx: { maxWidth: "38%" }, src: step1Image1 }), _jsx(OnboardingImage, { alt: "step 1 image 2", sx: { maxWidth: "58%" }, src: step1Image2 })] }), _jsx(OnboardingNextButton, { component: Link, to: `/${routes.onboarding}/${projectId}/2`, children: "Suivant" })] })] })] }));
    }
    if (step === "2") {
        return (_jsxs(OnboardingWrapper, { step: step, children: [_jsxs(OnboardingStepsContainer, { children: [_jsx(OnboardingStep, { active: true }), _jsx(OnboardingStep, { active: true })] }), _jsxs(OnboardingContainer, { children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(OnboardingStepNumber, { children: "2" }), _jsx(OnboardingTitle, { children: "On s'occupe du reste !" }), _jsxs(OnboardingText, { sx: { mt: 4 }, children: ["Tous les contenus sont rassembl\u00E9s en une seule vid\u00E9o gr\u00E2ce \u00E0 notre super montage automatique.", _jsx("br", {}), "L'organisateur\u00B7trice vous tient au courant, d\u00E8s que la vid\u00E9o collective est pr\u00EAte !"] })] }), _jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(OnboardingImage, { alt: "step 2", src: step2Image }), _jsx(OnboardingNextButton, { component: Link, to: `/${routes.contributor}/${routes.messages}/${projectId}/login`, sx: { mt: 6 }, children: "C'est parti" })] })] })] }));
    }
    return null;
}
