import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Typography, Box, Chip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { ProjectStatus } from '../../../models/project';
import { GreenButton } from '../../common/styles/buttons.styles';
import { routes } from "../../../App";
import { Page, PageTitle } from '../../common/styles/page.styles';
import { ProjectContent } from './ProjectContent/ProjectContent';
import { useProject } from '../../../contexts/project';
export default function ProjectDashboardPage() {
    const { project, isProjectLoading, updateProject } = useProject();
    const { id } = useParams();
    const [cookies] = useCookies();
    const role = cookies.role;
    if (isProjectLoading || !project) {
        return null;
    }
    const readonly = project.status !== ProjectStatus.ACTIVE;
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: "\u00C9diter mon projet" }), _jsxs(Box, { py: 2, textAlign: "center", children: [_jsx(Typography, { fontSize: 18, pb: 1, children: project.name }), project.status === ProjectStatus.COMPLETED && (_jsx(Chip, { label: "Vid\u00E9o finalis\u00E9e", color: "primary", size: "small" }))] }), role === "organizer" && (_jsx(Box, { py: 2, children: _jsx(ProjectContent, { project: project, updateProject: updateProject }) })), role === "organizer" && !readonly && (_jsx(Box, { py: 2, textAlign: "center", children: _jsx(GreenButton, { component: Link, to: `/${routes.user}/${routes.messages}/${id}/edit`, variant: "contained", startIcon: _jsx(Edit, {}), children: "Modifier les informations" }) }))] }));
}
