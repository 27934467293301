import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { GreenButton } from '../../../../common/styles/buttons.styles';
import { routes } from '../../../../../App';
import ProjectMediaInstructions from './ProjectMediaInstructions/ProjectMediaInstructions';
import ProjectMediaList from './ProjectMediaList/ProjectMediaList';
import { ProjectStatus } from '../../../../../models/project';
export default function ProjectMediaTab({ active, project }) {
    if (!active) {
        return null;
    }
    const readonly = project.status !== ProjectStatus.ACTIVE;
    return (_jsxs(Box, { p: 1, children: [!readonly && (_jsx(Box, { p: 1, textAlign: "center", children: _jsx(GreenButton, { component: Link, to: `/${routes.user}/${routes.messages}/${project.id}/media/ajouter`, variant: "contained", children: "Ajouter mon contenu" }) })), _jsx(ProjectMediaInstructions, { readonly: readonly }), _jsx(Box, { p: 1, children: _jsx(ProjectMediaList, { project: project }) })] }));
}
