import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import useApiRequest from '../../../hooks/api-request';
import { GreenButton } from '../../common/styles/buttons.styles';
import { useParams } from 'react-router-dom';
import { SectionTitle } from './OrderPage.styles';
export default function FreeOrderForm({ order, couponId, handleSuccess }) {
    const { id = '', orderId = null } = useParams();
    const API = useApiRequest();
    const { control, handleSubmit } = useForm({
        defaultValues: order || {
            firstName: "",
            lastName: "",
            address: "",
            zipCode: "",
            city: "",
            country: "",
            couponId,
        },
    });
    const [loading, setLoading] = useState(false);
    async function onSubmit(values) {
        try {
            setLoading(true);
            await API.createProjectOrder(id, values);
            await handleSuccess();
        }
        catch (e) {
            console.error("order creation error", e);
        }
        finally {
            setLoading(false);
        }
    }
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(Box, { children: [_jsx(SectionTitle, { children: "Adresse de facturation" }), _jsx(Controller, { name: "firstName", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Pr\u00E9nom", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "lastName", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Nom", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "address", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Adresse postale", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "zipCode", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Code postal", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "city", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Ville", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "country", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Pays", variant: "standard", type: "text", required: true, ...field })) })] }), _jsx(Box, { mt: 4, pb: 2, textAlign: "center", children: _jsx(GreenButton, { disabled: loading, type: "submit", children: "Valider" }) })] }));
}
