import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Container } from "@mui/material";
import { Stack } from "@mui/system";
import { LoginPageWrapper, LoginLoadingButton, LoginLogo, LoginTextField, LoginTitle, } from "./styles/Login.styles";
import { useFormInput } from "../../utils/FormInput";
import { routes } from "../../App";
import { OrangeButton } from "../common/styles/buttons.styles";
import { validateEmail } from "../../utils/formatting";
import { ProjectStatus } from '../../models/project';
import useApiRequest from '../../hooks/api-request';
import useUser from '../../hooks/user';
import { getRequestErrorMessage } from '../../utils/errors';
// @ts-ignore
import logo_black from "../../../public/logo_black.svg";
function ContributorLogin() {
    const navigate = useNavigate();
    const username = useFormInput("");
    const email = useFormInput("");
    const API = useApiRequest();
    const { setUserSession } = useUser();
    const [project, setProject] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    const { data } = await API.getProject(id);
                    setProject(data.project);
                }
                catch (error) {
                    const message = getRequestErrorMessage(error);
                    setError(message);
                }
            }
        })();
    }, [id]);
    async function handleLogin() {
        if (!id) {
            setError("Le project n'existe pas.");
            return;
        }
        if (!username.value || !email.value) {
            setError("Vous devez saisir un prénom et un email pour pouvoir participer.");
            return;
        }
        if (!validateEmail(email.value)) {
            setError("Veuillez saisir une adresse email valide.");
            return;
        }
        try {
            setError(null);
            setLoading(true);
            const { data } = await API.contributorLogin(id, email.value, username.value);
            setUserSession(data.contributor, "", "contributor");
            navigate(`/${routes.contributor}/${routes.messages}/${id}`);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
        finally {
            setLoading(false);
        }
    }
    if (!project) {
        return (_jsx(LoginPageWrapper, { maxWidth: false }));
    }
    const isProjectActive = project?.status === ProjectStatus.ACTIVE;
    return (_jsx(LoginPageWrapper, { maxWidth: false, children: _jsxs(Container, { children: [_jsx(LoginLogo, { src: logo_black }), isProjectActive && (_jsxs(_Fragment, { children: [_jsx(LoginTitle, { sx: { mt: 4, fontStyle: "italic" }, children: "Indiquez seulement votre pr\u00E9nom et email pour participer !" }), _jsx(Stack, { sx: { p: 2 }, children: _jsx(LoginTextField, { id: "outlined-basic", label: "Pr\u00E9nom", variant: "standard", type: "text", ...username }) }), _jsx(Stack, { sx: { p: 2 }, children: _jsx(LoginTextField, { id: "outlined-basic", label: "Adresse email", variant: "standard", type: "email", ...email }) }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) })), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsx(LoginLoadingButton, { variant: "contained", sx: { cursor: "pointer" }, loading: loading, onClick: handleLogin, children: "Participer" }) })] })), !isProjectActive && (_jsxs(_Fragment, { children: [_jsx(LoginTitle, { sx: { mt: 4, fontStyle: "italic" }, children: "Cette vid\u00E9o est maintenant cl\u00F4tur\u00E9e, cliquez ci-dessous pour acc\u00E9der \u00E0 la vid\u00E9o finale !" }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) })), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsx(OrangeButton, { component: Link, to: `/${routes.messages}/${id}/video-finale`, children: "Acc\u00E9der \u00E0 la vid\u00E9o finalis\u00E9e" }) })] }))] }) }));
}
export default ContributorLogin;
