import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Alert, Box, TextField, Typography, FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { Stack } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller, useForm } from "react-hook-form";
import { isDate, isFuture } from "date-fns";
import { fr } from "date-fns/locale";
import { OrangeButton } from "../common/styles/buttons.styles";
import { routes } from "../../App";
import { INITIAL_DESCRIPTION } from "./constants/constants";
import InfoTooltip from "../common/tooltips/InfoTooltip";
import { Page, PageTitle } from '../common/styles/page.styles';
import useApiRequest from '../../hooks/api-request';
import { getRequestErrorMessage } from '../../utils/errors';
function CreateMessage() {
    const navigate = useNavigate();
    const API = useApiRequest();
    const [error, setError] = useState(null);
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            name: "",
            orientation: "portrait",
            endDate: null,
            description: INITIAL_DESCRIPTION,
        },
    });
    async function onSubmit(values) {
        try {
            const response = await API.createProject(values);
            navigate(`/${routes.user}/${routes.messages}/${response.data.project.id}`);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: "Cr\u00E9ez votre vid\u00E9o collective" }), _jsx(Typography, { sx: { mb: 2, mt: 2 }, children: "C'est parti ! Commencez par choisir un titre. Vous pouvez aussi \u00E9crire aux participant\u00B7es un petit mot, des recommandations, et pourquoi pas une id\u00E9e de th\u00E8me ? C'est le meilleur moyen de recevoir des contenus au top ! Et pas de panique, vous pourrez encore modifier ces infos plus tard." }), _jsxs("form", { className: "flex flex-col", onSubmit: handleSubmit(onSubmit), children: [_jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: ["Titre de la vid\u00E9o collective", _jsx("span", { style: { color: "red" }, children: "*" })] }), _jsx(Controller, { name: "name", control: control, rules: {
                                    validate: (value) => value.trim().length > 0,
                                }, render: ({ field, ...rest }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "", variant: "outlined", type: "text", ...field })) })] }), _jsxs(Box, { sx: { textAlign: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: ["Quelle orientation choisissez-vous pour votre vid\u00E9o ?", " ", _jsx("span", { style: { color: "red" }, children: "*" }), _jsx(InfoTooltip, { children: _jsxs(Typography, { children: ["Cette information sera affich\u00E9e aux participant\u00B7es afin qu'ils envoient leur contenu dans l'orientation souhait\u00E9e.", _jsx("br", {}), "Attention, une fois choisie, il ne sera plus possible de changer l'orientation.", _jsx("br", {}), "Si vous souhaitez projeter la vid\u00E9o, nous vous conseillons l'orientation paysage."] }) })] }), _jsx(Controller, { name: "orientation", control: control, render: ({ field }) => (_jsx(FormControl, { required: true, children: _jsxs(RadioGroup, { row: true, "aria-label": "orientation", ...field, children: [_jsx(FormControlLabel, { value: "portrait", control: _jsx(Radio, {}), label: "Portrait" }), _jsx(FormControlLabel, { value: "paysage", control: _jsx(Radio, {}), label: "Paysage" })] }) })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: ["Date limite d'envoi des contenus ?", " ", _jsx("span", { style: { color: "red" }, children: "*" }), _jsx(InfoTooltip, { children: _jsx(Typography, { children: "Cette date limite ne s'applique que \u00E0 l'envoi des contenus des participant\u00B7es. L'organisateur\u00B7trice peut la modifier \u00E0 tout moment." }) })] }), _jsx(Controller, { name: "endDate", control: control, rules: {
                                    required: true,
                                    validate: (value) => {
                                        return !!value && isDate(new Date(value)) && isFuture(new Date(value));
                                    },
                                }, render: ({ field }) => (_jsx(LocalizationProvider, { adapterLocale: fr, dateAdapter: AdapterDateFns, children: _jsx(DatePicker, { disablePast: true, format: "dd/MM/yyyy", sx: { width: "fit-content", display: "flex", mt: 1 }, label: "dd/mm/yyyy", slotProps: {
                                            textField: {
                                                ...(formState?.errors?.endDate && {
                                                    error: true,
                                                    helperText: "Veuillez sélectionner une date",
                                                }),
                                                required: true,
                                            },
                                        }, ...field }) })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsx(Typography, { textAlign: "left", sx: { mb: 1 }, children: "Message aux participant\u00B7es" }), _jsx(Controller, { name: "description", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, multiline: true, id: "outlined-basic", label: "", variant: "outlined", type: "text", ...field })) })] }), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsx(OrangeButton, { type: "submit", variant: "contained", disabled: !formState.isValid || formState.isSubmitting, children: "Cr\u00E9er" }) })] }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) }))] }));
}
export default CreateMessage;
