import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useParams, Link } from "react-router-dom";
import { WhiteSecondaryButton } from '../../common/styles/buttons.styles';
import { routes } from "../../../App";
import { Page } from '../../common/styles/page.styles';
import { CheckImage, Logo, ThanksMessage } from './ContributorThanksPage.styles';
import UploadErrorModal from '../MediaUpload/UploadErrorModal';
// @ts-ignore
import logo_black from "../../../../public/logo_black.svg";
// @ts-ignore
import checkOrangeIcon from "../../../../public/check_orange.svg";
import useUrlSearchParams from '../../../hooks/url-search-params';
export default function ContributorThanksPage() {
    const { id } = useParams();
    const params = useUrlSearchParams();
    const [hasInvalidFiles, setHasInvalidFiles] = React.useState(!!params.get("has_invalid_files"));
    const [isUploadErrorModalOpen, setUploadErrorModalOpen] = useState(false);
    useEffect(() => {
        if (hasInvalidFiles) {
            setUploadErrorModalOpen(true);
        }
    }, [hasInvalidFiles]);
    function handleUploadErrorModalClose() {
        setUploadErrorModalOpen(false);
    }
    return (_jsxs(Page, { sx: { display: "flex", flexDirection: "column", height: "100%" }, children: [_jsxs(Box, { flex: 3, pt: 4, pb: 4, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", children: [_jsx(Logo, { src: logo_black }), _jsxs(ThanksMessage, { py: 2, children: ["Super ! ", _jsx("br", {}), " Merci d'avoir particip\u00E9\u00B7e."] }), _jsx(CheckImage, { alt: "check", src: checkOrangeIcon }), _jsxs(Typography, { py: 2, textAlign: "center", children: ["Vos contenus ont bien \u00E9t\u00E9 envoy\u00E9s \u00E0 l'organisateur\u00B7trice.", _jsx("br", {}), "D\u00E8s que la vid\u00E9o collective sera pr\u00EAte \u00E0 faire des \u00E9tincelles, vous aurez de ses nouvelles !"] }), hasInvalidFiles && (_jsx(UploadErrorModal, { open: isUploadErrorModalOpen, error: "Certains fichiers ne sont pas valides et n'ont pas pu \u00EAtre ajout\u00E9s.", onClose: handleUploadErrorModalClose }))] }), _jsx(Box, { flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", children: _jsx(WhiteSecondaryButton, { component: Link, to: `/${routes.contributor}/${routes.messages}/${id}/media/ajouter`, children: "Ajouter un nouveau contenu" }) })] }));
}
