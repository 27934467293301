import { styled } from '@mui/material/styles';
import { Slider } from '@mui/material';
export const StyledSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.secondary.main,
    height: 3,
    padding: '13px 0 !important',
    '& .MuiSlider-thumb': {
        height: 30,
        width: 15,
        borderRadius: 4,
        backgroundColor: theme.palette.secondary.main,
        border: '1px solid currentColor',
        '&:hover, &:focus, &:focus-visible, &:focus-within': {
            boxShadow: "none",
        },
        '& .bar': {
            height: 20,
            width: 3,
            backgroundColor: theme.palette.common.white,
            marginLeft: 1,
            marginRight: 1,
            cursor: "pointer",
        },
        '&[data-index="0"]': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            transform: "translate(0%, -50%)",
        },
        '&[data-index="1"]': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            transform: "translate(-100%, -50%)",
        }
    },
    '& .MuiSlider-track': {
        height: 24,
        borderRadius: 8,
        backgroundColor: "#eeeeee",
        opacity: 1,
        borderTop: "3px solid currentColor",
        borderBottom: "3px solid currentColor",
    },
    '& .MuiSlider-rail': {
        borderRadius: 5,
        color: "#fad9ce",
        opacity: 1,
        // color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
        // opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 30,
    },
}));
