import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Stack, useTheme } from '@mui/material';
import { SecondaryWhiteButton } from '../../../../../common/styles/buttons.styles';
import { ModalWrapper, SmallModalContent, ModalTitle, ModalCloseButton } from '../../../../../common/styles/modals.styles';
export default function ProjectMusicFileDeleteModal({ file, onClose, onDelete }) {
    const theme = useTheme();
    if (!file) {
        return null;
    }
    return (_jsx(Modal, { open: !!file, onClose: onClose, "aria-labelledby": "supprimer la musique d'un projet", "aria-describedby": "confirmation de la suppression de la musique", children: _jsx(ModalWrapper, { children: _jsxs(SmallModalContent, { display: "flex", flexDirection: "column", justifyContent: "center", children: [_jsx(ModalCloseButton, { onClick: onClose }), _jsx(ModalTitle, { sx: { pt: 0 }, children: "\u00CAtes-vous s\u00FBr de vouloir supprimer l'\u00E9l\u00E9ment ?" }), _jsxs(Stack, { mt: 4, direction: { xs: "column", sm: "row-reverse" }, spacing: 2, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "center", children: [_jsx(SecondaryWhiteButton, { onClick: onDelete, sx: {
                                    maxWidth: "none",
                                    background: theme.palette.common.white,
                                    color: theme.palette.secondary.main
                                }, children: "Oui" }), _jsx(SecondaryWhiteButton, { onClick: onClose, sx: {
                                    maxWidth: "none",
                                }, children: "Non" })] })] }) }) }));
}
