import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Container, } from "@mui/material";
import { GreenButton } from "../../common/styles/buttons.styles";
import useApiRequest from '../../../hooks/api-request';
import { routes } from '../../../App';
import { Page, PageTitle } from '../../common/styles/page.styles';
import ProjectPreviewVideo from '../ProjectPreviewVideo';
import { getLastOrder, isOrderPaid } from '../../../utils/orders';
import { getRequestErrorMessage } from '../../../utils/errors';
export default function PreviewPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    const API = useApiRequest();
    const [project, setProject] = useState();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // fetch project
    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    setIsLoading(true);
                    const projectResponse = await API.getProject(id);
                    // If video is finalised redirect to final video page
                    if (projectResponse.data?.project?.finalVideo) {
                        navigate(`/${routes.messages}/${id}/video-finale`);
                        return;
                    }
                    const ordersResponse = await API.getProjectOrders(id);
                    const order = getLastOrder(ordersResponse.data.orders);
                    if (order && isOrderPaid(order)) {
                        navigate(`/${routes.messages}/${id}/video-finale`);
                        return;
                    }
                    setProject(projectResponse.data.project);
                }
                catch (error) {
                    const message = getRequestErrorMessage(error);
                    setError(message);
                }
            }
        })();
    }, [id]);
    function handleVideoLoaded() {
        setIsLoading(false);
    }
    if (!project) {
        return null;
    }
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: project?.name }), _jsxs(Container, { sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }, children: [!project?.files?.length && (_jsx(Typography, { sx: { mt: 1 }, children: "Il n'y a encore aucun contenu envoy\u00E9." })), _jsx(Box, { sx: { mt: 2, mb: 4, width: "100%", textAlign: "center" }, children: _jsx(ProjectPreviewVideo, { project: project, onVideoLoaded: handleVideoLoaded }) }), !!project?.files?.length && !isLoading && (_jsx(GreenButton, { component: Link, to: `/${routes.user}/${routes.messages}/${id}/panier`, children: "Cl\u00F4turer" }))] })] }));
}
