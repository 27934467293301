import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Alert, Container, Link, Typography } from '@mui/material';
import { Stack } from "@mui/system";
import { LoginLoadingButton, LoginLogo, LoginPageWrapper, LoginTextField, LoginTitle, } from './styles/Login.styles';
import { useFormInput } from "../../utils/FormInput";
import useUser from '../../hooks/user';
import useApiRequest from '../../hooks/api-request';
import { routes } from "../../App";
import { validateEmail } from "../../utils/formatting";
import PasswordField from './PasswordField';
import { getRequestErrorMessage } from '../../utils/errors';
// @ts-ignore
import logo_black from "../../../public/logo_black.svg";
function Signup() {
    const navigate = useNavigate();
    const username = useFormInput("");
    const email = useFormInput("");
    const password = useFormInput("");
    const API = useApiRequest();
    const { setUserSession } = useUser();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    async function handleSignup() {
        if (!username.value || !email.value || !password.value) {
            setError("Merci de remplir tous les champs pour pouvoir finaliser l'inscription.");
            return;
        }
        if (!validateEmail(email.value)) {
            setError("Veuillez saisir une adresse email valide.");
            return;
        }
        try {
            setError(null);
            setLoading(true);
            const { data } = await API.organizerSignup(username.value, email.value, password.value);
            setUserSession(data.user, data.token, "organizer");
            navigate(`/${routes.user}/${routes.messages}`);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
        finally {
            setLoading(false);
        }
    }
    return (_jsx(LoginPageWrapper, { maxWidth: false, children: _jsxs(Container, { children: [_jsx(LoginLogo, { src: logo_black }), _jsx(LoginTitle, { children: "Inscription" }), _jsx(Stack, { sx: { p: 2 }, children: _jsx(LoginTextField, { id: "outlined-basic", label: "Pr\u00E9nom", variant: "standard", type: "text", ...username }) }), _jsx(Stack, { sx: { p: 2 }, children: _jsx(LoginTextField, { id: "outlined-basic", label: "Adresse email", variant: "standard", type: "email", ...email }) }), _jsx(Stack, { sx: { p: 2 }, children: _jsx(PasswordField, { label: "Mot de passe", ...password }) }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) })), _jsx(Stack, { sx: { p: 2 }, children: _jsxs(Typography, { variant: "body2", sx: { textAlign: "left" }, children: [_jsx("span", { children: "Vous avez d\u00E9j\u00E0 un compte ?" }), _jsx(Link, { component: RouterLink, sx: { ml: 0.5, color: "black" }, to: `/login`, children: "Se connecter" })] }) }), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsx(LoginLoadingButton, { variant: "contained", sx: { cursor: "pointer" }, loading: loading, onClick: handleSignup, children: "Valider mon inscription" }) })] }) }));
}
export default Signup;
