import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Box, Slider } from '@mui/material';
import { VolumeDown, VolumeUp } from '@mui/icons-material';
const DEFAULT_VOLUME = 5;
export default function VolumeField({ volume, disabled, onChange, onChangeCommitted }) {
    useEffect(() => {
        if (typeof volume !== 'number') {
            onChange(DEFAULT_VOLUME);
        }
    }, [volume]);
    if (typeof volume !== 'number') {
        return null;
    }
    return (_jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", width: "100%", children: [volume <= 5 ? _jsx(VolumeDown, { color: (disabled || volume === 0) ? "disabled" : "secondary" }) : _jsx(VolumeUp, { color: disabled ? "disabled" : "secondary" }), _jsx(Slider, { size: "small", valueLabelDisplay: "auto", className: volume === 0 ? "Mui-disabled zero" : "", color: "secondary", min: 0, max: 10, step: 1, defaultValue: DEFAULT_VOLUME, value: volume, disabled: disabled, onChange: (_, value) => onChange(value), onChangeCommitted: (_, value) => onChangeCommitted(value), sx: {
                    flex: 1,
                    ml: 1,
                    cursor: disabled ? 'default' : 'pointer !important',
                    pointerEvents: disabled ? 'none' : 'auto !important',
                } })] }));
}
