import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Page, PageTitle } from '../../common/styles/page.styles';
import { ProjectStatus } from '../../../models/project';
import useApiRequest from '../../../hooks/api-request';
import { routes } from '../../../App';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { theme } from '../../../utils/theme';
import ProjectPreviewVideo from '../ProjectPreviewVideo';
import FinalVideoLoadingModal from './FinalVideoLoadingModal';
import { getLastOrder, isOrderPaid } from '../../../utils/orders';
import { ProjectVideo } from './ProjectVideo';
import DownloadButton from './DownloadButton';
import ShareButton from './ShareButton';
const pause = (ms) => new Promise(resolve => setTimeout(resolve, ms));
export default function FinalVideoPage({ isThankYou = false }) {
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const API = useApiRequest();
    const { id } = useParams();
    const [project, setProject] = useState();
    const user = cookies.user;
    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    const projectResponse = await API.getProject(id);
                    const project = projectResponse.data.project;
                    if (project.status === ProjectStatus.ACTIVE) {
                        const ordersResponse = await API.getProjectOrders(project.id);
                        const order = getLastOrder(ordersResponse.data.orders);
                        if (!order || !isOrderPaid(order)) {
                            if (user?.email === project.organizer) {
                                navigate(`/${routes.user}/${routes.messages}/${id}/preview`);
                            }
                            else {
                                navigate(`/${routes.contributor}/${routes.messages}/${id}`);
                            }
                            return;
                        }
                    }
                    setProject(project);
                }
                catch (error) {
                    console.log('Error white loading project', error);
                }
            }
        })();
    }, [id]);
    useEffect(() => {
        (async () => {
            if (project && !project.finalVideo) {
                try {
                    let completed = !!project.finalVideo;
                    let attempts = 1;
                    while (!completed) {
                        const response = await API.getProject(project.id);
                        completed = !!response.data.project.finalVideo;
                        if (completed) {
                            setProject(response.data.project);
                        }
                        else if (attempts === 3 && response.data.project.status === ProjectStatus.ACTIVE) {
                            // if after 30sec the status of the project is not FINALIZING,
                            // it means the project won't finalize automatically
                            await API.finalizeProject(project.id);
                        }
                        else {
                            await pause(10000);
                        }
                        attempts++;
                    }
                }
                catch (error) {
                    console.log('Error verifying project status', error);
                }
            }
        })();
    }, [project]);
    function handleModalClose() {
        navigate(`/${routes.user}/${routes.messages}/${id}`);
    }
    if (!project) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsxs(Page, { sx: { padding: "0 !important" }, children: [_jsx(PageTitle, { children: isThankYou ? "Bravo !" : project.name }), isThankYou && (_jsx(Typography, { sx: { mt: 1, textAlign: "center" }, children: "Bravo ! Votre vid\u00E9o collective est pr\u00EAte \u00E0 faire des \u00E9tincelles ! Vous pouvez d\u00E8s maintenant la t\u00E9l\u00E9charger et la partager \u00E0 vos ami\u00B7es" })), _jsxs(Container, { sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }, children: [_jsx(Box, { sx: { my: 2, width: '100%', textAlign: "center" }, children: project?.finalVideo
                            ? _jsx(ProjectVideo, { project: project })
                            : _jsx(ProjectPreviewVideo, { project: project }) }), _jsxs(Box, { sx: { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "middle" }, children: [_jsx(DownloadButton, { project: project }), _jsx(ShareButton, { project: project })] })] }), _jsx(FinalVideoLoadingModal, { open: project.status !== ProjectStatus.COMPLETED, onClose: handleModalClose })] }));
}
