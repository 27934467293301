import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { Preview } from "@creatomate/preview";
import { buildMediaPreview } from '../../Project/utils/creatomate';
import { LANDSCAPE_WIDTH, LANDSCAPE_MOBILE_WIDTH, PORTRAIT_HEIGHT, getMediaPreviewCommonSx } from '../styles/media-previews';
export const CREATOMATE_PREVIEW_CONTAINER = "creatomate-preview-container";
export default function CreatomateMediaPreview({ media, orientation, onVideoLoaded, ignoreMediaText = false }) {
    const theme = useTheme();
    const containerRef = useRef();
    const previewRef = useRef();
    const [previewJson, setPreviewJson] = useState();
    const [loading, setLoading] = useState(false);
    const [isVideoLoading, setVideoLoading] = useState(false);
    useEffect(() => {
        if (media) {
            const json = buildMediaPreview(media, orientation, ignoreMediaText);
            setLoading(!!json);
            setPreviewJson(json);
            setLoading(true);
        }
    }, [media, orientation]);
    // update preview when previewJson changed
    useEffect(() => {
        if (previewJson && containerRef.current && !isVideoLoading) {
            if (previewRef.current) {
                previewRef.current.dispose();
                previewRef.current = undefined;
            }
            setVideoLoading(true);
            const preview = new Preview(containerRef.current, "player", "public-ov8hodzu8uhv4vevatg2r22r");
            preview.onReady = async () => {
                try {
                    // Prevent loading issue on safari
                    console.log('Effect preview ready: set cache bypass rules');
                    await preview.setCacheBypassRules([
                        /^https?:\/\/(staging\.)?atan6431\.odns\.fr\//,
                        /^https?:\/\/(www\.)?(dev\.|dev2\.|app\.)?memorable\.love\//,
                    ]);
                    console.log('Effect preview ready: set source');
                    await preview.setSource(previewJson || {});
                }
                catch (e) {
                    console.error('Error setting creatomate preview source', e);
                }
                setLoading(false);
                setVideoLoading(false);
                if (typeof onVideoLoaded === 'function') {
                    onVideoLoaded();
                }
            };
            previewRef.current = preview;
        }
    }, [previewJson, containerRef]);
    if (!media || (!loading && !previewJson)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [loading && (_jsx(Box, { component: "div", sx: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...getMediaPreviewCommonSx(orientation),
                }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) })), _jsx(Box, { id: CREATOMATE_PREVIEW_CONTAINER, component: "div", ref: containerRef, sx: {
                    ...getMediaPreviewCommonSx(orientation),
                    ...(orientation === "paysage"
                        ? { width: loading ? 0 : LANDSCAPE_WIDTH }
                        : { height: loading ? 0 : PORTRAIT_HEIGHT }),
                    ["@media (max-width:960px)"]: {
                        ...(orientation === "paysage"
                            ? {
                                width: loading ? 0 : LANDSCAPE_MOBILE_WIDTH
                            }
                            : {}),
                    },
                } })] }));
}
