import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import useApiRequest from '../../../hooks/api-request';
import { GreenButton } from '../../common/styles/buttons.styles';
import { useParams } from 'react-router-dom';
import { routes } from '../../../App';
import { SectionTitle } from './OrderPage.styles';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { theme } from '../../../utils/theme';
import StripePaymentElement from './StripePaymentElement';
export default function OrderForm({ order, couponId, handlePaymentSuccess }) {
    const { id = '', orderId = null } = useParams();
    const API = useApiRequest();
    const { control, handleSubmit } = useForm({
        defaultValues: order || {
            firstName: "",
            lastName: "",
            address: "",
            zipCode: "",
            city: "",
            country: "",
            couponId,
        },
    });
    // Stripe
    const stripe = useStripe();
    const elements = useElements();
    const [isStripeLoading, setStripeLoading] = useState(true);
    const [isStripeError, setStripeError] = useState(false);
    const [isStripeSuccess, setStripeSuccess] = useState(false);
    const [stripeMessage, setStripeMessage] = useState();
    useEffect(() => {
        if (!stripe) {
            return;
        }
        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) {
            setStripeLoading(false);
            return;
        }
        setStripeError(false);
        setStripeSuccess(false);
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent?.status) {
                case "succeeded":
                    setStripeSuccess(true);
                    return handlePaymentSuccess();
                case "processing":
                    setStripeMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setStripeMessage("Your payment was not successful, please try again.");
                    setStripeError(true);
                    break;
                default:
                    setStripeMessage("Something went wrong.");
                    setStripeError(true);
                    break;
            }
            setStripeLoading(false);
        });
    }, [stripe]);
    async function onSubmit(values) {
        try {
            const response = await API.createProjectOrder(id, values);
            const order = response.data.order;
            if (!stripe || !elements) {
                return;
            }
            setStripeLoading(true);
            setStripeError(false);
            setStripeSuccess(false);
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${process.env.REACT_APP_BASE_URL}/${routes.user}/${routes.messages}/${id}/commande/${order.id}`
                },
            });
            if (error.type === "card_error" || error.type === "validation_error" || error.type === "invalid_request_error") {
                setStripeMessage(error.message);
                setStripeError(true);
            }
            else {
                setStripeMessage(error.type && error.message ? error.message : "Une erreur est survenue lors du paiement de la commande, veuillez ré-essayer.");
                setStripeError(true);
            }
            setStripeLoading(false);
        }
        catch (e) {
            console.error("order creation error", e);
        }
    }
    if (orderId || isStripeSuccess) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(Box, { children: [_jsx(SectionTitle, { children: "Adresse de facturation" }), _jsx(Controller, { name: "firstName", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Pr\u00E9nom", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "lastName", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Nom", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "address", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Adresse postale", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "zipCode", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Code postal", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "city", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Ville", variant: "standard", type: "text", required: true, ...field })) }), _jsx(Controller, { name: "country", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "outlined-basic", label: "Pays", variant: "standard", type: "text", required: true, ...field })) })] }), _jsxs(Box, { sx: { mt: 4 }, children: [_jsx(SectionTitle, { children: "Paiement" }), isStripeError && (_jsx(Typography, { sx: { my: 2, color: theme.palette.error.main }, children: stripeMessage })), _jsx(StripePaymentElement, { control: control })] }), _jsx(Box, { mt: 4, pb: 2, textAlign: "center", children: _jsx(GreenButton, { disabled: isStripeLoading || !stripe || !elements, type: "submit", children: "Valider" }) })] }));
}
