import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';
import { Box, Typography, useTheme } from '@mui/material';
import { ProjectStatus } from '../../../../../models/project';
import VolumeField from '../../../../common/VolumeField/VolumeField';
import { JINGLE_DURATION } from '../../../utils/creatomate';
import ProjectMusicUpload from './ProjectMusicUpload';
import useApiRequest from '../../../../../hooks/api-request';
import ProjectMusicSelection from './ProjectMusicSelection/ProjectMusicSelection';
import { MUSIC_DEFAULT_VALUE, MUSIC_DEFAULT_VOLUME, MUSIC_LIBRARY_IDS, MUSIC_PREVIEW_DURATION } from './ProjectMusicTab.utils';
import useAudioPlayer from '../../../../../hooks/audio-player';
import ProjectMusicLibrary from './ProjectMusicLibrary/ProjectMusicLibrary';
let musicPreviewTimout;
export default function ProjectMusicTab({ active, project, updateProject }) {
    const theme = useTheme();
    const [cookies] = useCookies();
    const API = useApiRequest();
    const [loadingFiles, setLoadingFiles] = useState([]);
    const [projectMusicFiles, setProjectMusicFiles] = useState([]);
    const [projectMusicVolume, setProjectMusicVolume] = useState(MUSIC_DEFAULT_VOLUME);
    const [finalVideoDuration, setFinalVideoDuration] = useState(0);
    const [playingMusicFile, setPlayingMusicFile] = useState();
    const { audioNode, createAudio, destroyAudio, playAudio, pauseAudio, setAudioVolume, isAudioPlaying } = useAudioPlayer();
    const user = cookies.user;
    useEffect(() => {
        if (project.files) {
            const duration = project.files.reduce((total, file) => {
                if (file.type === 'video' && typeof file.startAt === "number" && typeof file.endAt === "number") {
                    return total + (file.endAt - file.startAt);
                }
                return total + file.duration;
            }, JINGLE_DURATION);
            setFinalVideoDuration(duration);
        }
    }, [project.files]);
    useEffect(() => {
        if (project.music) {
            setProjectMusicFiles(project.music.files);
            setProjectMusicVolume(typeof project.music?.volume === "number" ? project.music.volume : MUSIC_DEFAULT_VOLUME);
        }
    }, [project.music]);
    useEffect(() => {
        setAudioVolume(projectMusicVolume);
    }, [projectMusicVolume]);
    // Ensure audio stops when navigating away
    useEffect(() => {
        if (!active && isAudioPlaying) {
            destroyAudio();
        }
    }, [active, isAudioPlaying]);
    useEffect(() => {
        if (audioNode) {
            function endListener() {
                setPlayingMusicFile(undefined);
            }
            audioNode.addEventListener('ended', endListener);
            return () => {
                audioNode.removeEventListener('ended', endListener);
            };
        }
    }, [audioNode]);
    useEffect(() => {
        (async () => {
            if (playingMusicFile) {
                await createAudio(playingMusicFile.file_path, true);
                // for library music, stop after preview duration
                if (MUSIC_LIBRARY_IDS.includes(playingMusicFile.id)) {
                    await handleMusicStopAfterPreview();
                }
            }
            else if (isAudioPlaying) {
                await destroyAudio();
            }
        })();
    }, [playingMusicFile]);
    function updateProjectMusic(updates) {
        updateProject({
            music: Object.assign(MUSIC_DEFAULT_VALUE, project.music, updates)
        });
    }
    async function updateProjectMusicFiles(files) {
        if (project?.id) {
            try {
                const response = await API.updateProjectMusicFiles(project.id, files);
                setProjectMusicFiles(response.data.files);
                updateProjectMusic({ files: response.data.files });
            }
            catch (error) {
                console.log("Error while updating project music files", error);
            }
        }
    }
    function handleUploadQueueUpdate(files) {
        setLoadingFiles(files);
    }
    async function deleteProjectMusicFile(fileId) {
        if (project?.id) {
            try {
                const response = await API.deleteProjectMusicFile(project.id, fileId);
                setProjectMusicFiles(response.data.files);
                updateProjectMusic({ files: response.data.files });
                if (fileId === playingMusicFile?.id) {
                    setPlayingMusicFile(undefined);
                }
            }
            catch (error) {
                console.log("Error while updating project music files", error);
            }
        }
    }
    async function handleVolumeChange(volume) {
        if (project?.id) {
            try {
                const response = await API.updateProjectMusic(project.id, { volume });
                updateProjectMusic(response.data.music);
                setProjectMusicVolume(response.data.music.volume);
            }
            catch (error) {
                console.log("Error while updating project music volume", error);
            }
        }
    }
    async function handleMusicPlay(file) {
        if (!playingMusicFile || playingMusicFile.id !== file.id) {
            setPlayingMusicFile(file);
        }
        else if (playingMusicFile.id === file.id) {
            await playAudio();
        }
    }
    async function handleMusicStop() {
        await destroyAudio();
        setPlayingMusicFile(undefined);
    }
    async function handleMusicStopAfterPreview() {
        musicPreviewTimout = setTimeout(() => {
            handleMusicStop();
            clearTimeout(musicPreviewTimout);
        }, MUSIC_PREVIEW_DURATION);
    }
    function handleMusicFileAdd(file) {
        setProjectMusicFiles((state) => [...state, file]);
        updateProjectMusic({ files: [...projectMusicFiles, file] });
    }
    async function handleLibraryMusicAdd(item) {
        try {
            const file = {
                id: uuid(),
                type: 'audio',
                file_name: item.file_name,
                file_path: item.file_path,
                cover: item.cover,
                duration: item.duration,
                userId: user.id,
                userName: user.first_name,
            };
            await updateProjectMusicFiles([...projectMusicFiles, file]);
        }
        catch (e) {
            console.log("Error while adding music from library", e);
        }
    }
    if (!active) {
        return null;
    }
    const readonly = project.status !== ProjectStatus.ACTIVE;
    return (_jsxs(Box, { p: 1, children: [_jsx(ProjectMusicSelection, { loadingFiles: loadingFiles, projectMusicFiles: projectMusicFiles, playingMusicFileId: playingMusicFile?.id, finalVideoDuration: finalVideoDuration, readonly: readonly, isPlayingMusic: isAudioPlaying, onMusicPlay: handleMusicPlay, onMusicPause: pauseAudio, onUpdateFiles: updateProjectMusicFiles, onDeleteFile: deleteProjectMusicFile }), _jsxs(Box, { px: 1, py: 2, children: [_jsx(Typography, { children: "Volume" }), _jsx(VolumeField, { volume: projectMusicVolume, disabled: readonly || projectMusicFiles.length === 0, onChange: setProjectMusicVolume, onChangeCommitted: handleVolumeChange })] }), !readonly && (_jsxs(_Fragment, { children: [_jsx(ProjectMusicLibrary, { playingMusicFileId: playingMusicFile?.id, onMusicPlay: handleMusicPlay, onMusicPause: handleMusicStop, onMusicAdd: handleLibraryMusicAdd }), _jsx(ProjectMusicUpload, { project: project, onMusicFileAdd: handleMusicFileAdd, onUploadQueueUpdated: handleUploadQueueUpdate })] }))] }));
}
