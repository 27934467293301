import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { Preview } from "@creatomate/preview";
import { buildPreviewJson } from './utils/creatomate';
import { LANDSCAPE_WIDTH, LANDSCAPE_MOBILE_WIDTH, PORTRAIT_HEIGHT, getMediaPreviewCommonSx } from '../common/styles/media-previews';
export default function ProjectPreviewVideo({ project, onVideoLoaded }) {
    const theme = useTheme();
    const previewRef = useRef(null);
    const [previewJson, setPreviewJson] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (project?.files?.length) {
            const json = buildPreviewJson(project);
            setLoading(!!json);
            setPreviewJson(json);
            setLoading(true);
        }
    }, [project]);
    // update preview when previewJson changed
    useEffect(() => {
        if (previewJson && previewRef.current) {
            const preview = new Preview(previewRef.current, "player", "public-ov8hodzu8uhv4vevatg2r22r");
            preview.onReady = async () => {
                await preview.setSource(previewJson || {});
                setLoading(false);
                if (typeof onVideoLoaded === 'function') {
                    onVideoLoaded();
                }
            };
        }
    }, [previewJson]);
    if (!project?.files?.length || (!loading && !previewJson)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [loading && (_jsx(Box, { component: "div", sx: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...getMediaPreviewCommonSx(project.orientation),
                }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) })), _jsx(Box, { component: "div", ref: previewRef, sx: {
                    ...getMediaPreviewCommonSx(project.orientation),
                    ...(project.orientation === "paysage"
                        ? { width: loading ? 0 : LANDSCAPE_WIDTH }
                        : { height: loading ? 0 : PORTRAIT_HEIGHT }),
                    ["@media (max-width:960px)"]: {
                        ...(project.orientation === "paysage"
                            ? {
                                width: loading ? 0 : LANDSCAPE_MOBILE_WIDTH
                            }
                            : {}),
                    },
                } })] }));
}
