import { jsx as _jsx } from "react/jsx-runtime";
import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
export const Page = styled(Container)(({ theme }) => ({
    justifyContent: "center",
    alignContent: "center",
    padding: `${theme.spacing(0, 0, 4)} !important`,
}));
export const PageTitle = styled(({ ...props }) => (_jsx(Typography, { ...props, variant: "h3" })))(({ theme }) => ({
    fontFamily: "var(--main_font_family_secondary)",
    lineBreak: "normal",
    fontSize: "1.5rem !important",
    fontStyle: "italic",
    color: theme.palette.primary.main,
    textAlign: "center",
    margin: theme.spacing(1, 0, 2, 0),
    lineHeight: 1.5,
}));
export const ProjectListPageTitle = styled(PageTitle)(({ theme }) => ({
    textAlign: "left",
    margin: theme.spacing(3, 0, 2, 0),
}));
